import { useAuth0 } from '@auth0/auth0-react';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { useEffect, FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

type PrivateRouteProps = {};

export const PrivateRoute: FC<PropsWithChildren<(PrivateRouteProps)>> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      const path = pathname;
      const params = search;

      await loginWithRedirect({
        appState: { targetUrl: `${path}${params}` },
      });
    };

    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, pathname, search]);

  if (!isAuthenticated || isLoading) {
    return (
      <StyledLoaderContainer>
        <CircularSpinner shown={true} />
      </StyledLoaderContainer>
    );
  } else if (!user) {
    return <Navigate to={'/logout'} replace />;
  }

  return <>{children}</>;
};

const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
