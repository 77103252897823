import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { DataReportUploadFileDefinition, usePpDownloadCsvMutation, usePpDownloadTemplateCsvMutation } from 'graphql/types';
import { DialogActions, List, ListItem, Typography } from '@mui/material';
import styled from 'styled-components';
import { FileDownloadButton } from './FileDownloadButton';
import { gql } from '@apollo/client';
import { useAppSelector } from 'store/hooks';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { useAuth0 } from '@auth0/auth0-react';

export type FileDownloadDialogProps = {
  open: boolean;
  partner: DataReportUploadFileDefinition | null;
  onClose: () => void;
};

export const FileDownloadDialog: React.FC<FileDownloadDialogProps> = ({ open, partner, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  const { getIdTokenClaims } = useAuth0();

  const getToken = React.useRef(getIdTokenClaims);

  React.useEffect(() => {
    getToken.current = getIdTokenClaims;
  });

  const partnerId = useAppSelector(state => state.app.partnerId);

  const { createFailureNotification } = useMuiNotifications();
  const [downloadCsvMutation] = usePpDownloadCsvMutation();
  const [downloadTemplateCsvMutation] = usePpDownloadTemplateCsvMutation();
  const [downloading, setDownloading] = React.useState(false);

  const downloadCsv = async (downloadUrl: string, isSignedUrl: boolean, contentType?: string) => {
    setDownloading(true);

    const idToken = (await getToken.current())?.__raw;

    fetch(downloadUrl, {
      method: 'GET',
      redirect: 'follow',
      headers: {
        ...(isSignedUrl ? undefined : { Authorization: `Bearer ${idToken}` }),
        ...(contentType ? { 'Content-Type': contentType } : undefined),
      },
    })
      .then(response => {
        setDownloading(false);
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${partner?.tableName ?? ''}.csv`;
          a.click();
        });
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  const getSignedCsvUrl = () => {
    if (partner?.reportType && partnerId) {
      setDownloading(true);
      downloadCsvMutation({
        variables: {
          partnerId: partnerId,
          reportType: partner?.reportType
        },
        onCompleted(data) {
          setDownloading(false);
          if (data.generateDownloadFileUrlData.signedS3URL) {
            // download signedS3Url
            const url = data.generateDownloadFileUrlData.signedS3URL;
            downloadCsv(url, true);
          } else {
            downloadTemplateCsvMutation({
              variables: {
                partnerId: partnerId,
                reportType: partner?.reportType
              },
              onCompleted(data) {
                setDownloading(false);
                const url = data?.generateDownloadTemplateFileUrlData?.signedS3URL;
                if (url) {
                  downloadCsv(url, true);
                }
              },
              onError(error) {
                setDownloading(false);
                createFailureNotification(`Failed: ${error.message}`);
              },
            });
          }
        },
        onError(error) {
          setDownloading(false);
          createFailureNotification(`Failed: ${error.message}`);
        },
      });
    }
  };

  const listItems = (partner?.downloadInstructions || []).map((item, index) => {
    return (
      <ListItem key={`${index}_list_item`} disablePadding sx={{ maxWidth: '440px', padding: '8px' }}>
        <div dangerouslySetInnerHTML={{ __html: item }} />
      </ListItem>
    );
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}>
      <StyledDialogContent sx={{ width: '562px' }}>
        <Typography
          textAlign={'center'}
          sx={{
            fontSize: 30,
            fontWeight: 800,
            color: '#000000',
            mb: '20px',
            mt: '10px',
          }}>
          Instructions
        </Typography>
        <List sx={{ width: 'fit-content' }}>
          {listItems}
        </List>
      </StyledDialogContent>
      <StyledDialogActions>
        <FileDownloadButton
          partner={partner}
          loading={downloading}
          onClicked={getSignedCsvUrl}
        />
      </StyledDialogActions>
    </Dialog>
  );
}

const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '40px',
});

export const pp_download_csv = gql`
  mutation pp_download_csv($partnerId: String!, $reportType: DataReportType!) {
    generateDownloadFileUrlData
    (
      partnerId: $partnerId,
      reportType: $reportType
    )
    {
      signedS3URL
    }
  }
`;

export const pp_download_template_csv = gql`
  mutation pp_download_template_csv($partnerId: String!, $reportType: DataReportType!) {
    generateDownloadTemplateFileUrlData
    (
      partnerId: $partnerId,
      reportType: $reportType
    )
    {
      signedS3URL
    }
  }
`;