import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BillingCurrentPlanView } from './parts/BillingCurrentPlanView';
import { Divider, Typography } from '@mui/material';
import { BillingPaymentMethodView } from './parts/BillingPaymentMethodView';
import { BillingInformationView } from './parts/BillingInformationView';
import { BillingInvoiceHistoryView } from './parts/BillingInvoiceHistoryView';
import { gql } from '@apollo/client';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { actions } from 'store/reducers/app';
import { useStripeAddPaymentMethodMutation, useStripeSetPaymentMethodAsDefaultMutation } from 'graphql/types';
import { AlertDialog, AlertType } from 'components/dialog/AlertDialog';

export const BillingView: FC = () => {
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const isSetAsDefault = useAppSelector((state) => state.app.paymentMethodSetDefault);
  const paymentMethodAddingStatus = useAppSelector((state) => state.app.paymentMethodAddingStatus);
  const paymentMethodAddingId = useAppSelector((state) => state.app.paymentMethodAddingMethodId);
  const [stripeAddPaymentMethodMutation] = useStripeAddPaymentMethodMutation();
  const [stripeSetPaymentMethodAsDefaultMutation] = useStripeSetPaymentMethodAsDefaultMutation();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.Failed);
  const [alertMessage, setAlertMessage] = useState('');
  const dispatch = useAppDispatch();

  const handleOK = useCallback(() => {
    setShowAlert(false);
    dispatch(actions.setReloadStripeClientSecret(true));
    dispatch(actions.setPaymentMethodAddingStatus(null));
    dispatch(actions.setPaymentMethodAddingMethodId(null));
    dispatch(actions.setReloadPaymentMethods(true));
  }, [dispatch]);

  useEffect(() => {
    const handleAddMethod = async () => {
      if (!paymentMethodAddingId || !partnerId) {
        return;
      }

      if (isSetAsDefault) {
        const resultDefault = await stripeSetPaymentMethodAsDefaultMutation({
          variables: {
            partnerId: partnerId,
            paymentMethodId: paymentMethodAddingId,
          },
        });
        if (resultDefault && resultDefault.data?.setPaymentMethodAsDefaultStripe === true) {
          setAlertType(AlertType.Success);
          setAlertMessage('Your payment method has been saved.');
          setShowAlert(true);
        } else {
          setAlertType(AlertType.Failed);
          setAlertMessage('Failed to set your payment method as default. Please retry again or contact support.');
          setShowAlert(true);
        }
      } else {
        setAlertType(AlertType.Success);
        setAlertMessage('Your payment method has been saved.');
        setShowAlert(true);
      }
    };

    if (!paymentMethodAddingStatus) {
      return;
    }

    switch (paymentMethodAddingStatus) {
      case 'succeeded':
        //setMessage('Success! Your payment method has been saved.');
        handleAddMethod();
        break;
      case 'processing':
        break;
      case 'requires_payment_method':
        // Redirect your user back to your payment page to attempt collecting
        // payment again
        //setMessage('Failed to process payment details. Please try another payment method.');
        break;
    }
  }, [
    partnerId,
    paymentMethodAddingStatus,
    paymentMethodAddingId,
    isSetAsDefault,
    stripeAddPaymentMethodMutation,
    stripeSetPaymentMethodAsDefaultMutation,
  ]);

  return (
    <StyledContainer>
      <TitleTypography>CURRENT PLAN</TitleTypography>
      <Divider />
      <BillingCurrentPlanView />
      <TitleTypography>PAYMENT METHOD</TitleTypography>
      <Divider />
      <BillingPaymentMethodView />
      <TitleTypography>BILLING INFORMATION</TitleTypography>
      <Divider />
      <BillingInformationView />
      <TitleTypography>INVOICE HISTORY</TitleTypography>
      <Divider />
      <BillingInvoiceHistoryView />
      <AlertDialog showDialog={showAlert} type={alertType} message={alertMessage} okTitle="Ok" onOK={handleOK} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
`;

const TitleTypography = styled(Typography)({
  fontSize: 15,
  fontWeight: 500,
  marginBottom: '8px',
});

export const StripeSetupIntentMutation = gql`
  mutation StripeSetupIntent($partnerId: String!) {
    setupIntentStripe(partnerId: $partnerId) {
      clientSecret
    }
  }
`;

export const StripeGetPaymentMethodsQuery = gql`
  query StripeGetPaymentMethods($partnerId: String!) {
    getPaymentMethodsStripe(partnerId: $partnerId) {
      type
      paymentMethodId
      isDefault
      cardType
      cardLast4
      cardExpirationYear
      cardExpirationMonth
      bankName
      bankAccountType
      bankAccountLast4
      linkEmail
    }
  }
`;

export const StripeAddPaymentMethodMutation = gql`
  mutation StripeAddPaymentMethod($partnerId: String!, $paymentMethodId: String!) {
    addPaymentMethodStripe(partnerId: $partnerId, paymentMethodId: $paymentMethodId)
  }
`;

export const StripeRemovePaymentMethodMutation = gql`
  mutation StripeRemovePaymentMethod($partnerId: String!, $paymentMethodId: String!) {
    removePaymentMethodStripe(partnerId: $partnerId, paymentMethodId: $paymentMethodId)
  }
`;

export const StripeRemoveAllPaymentMethodMutation = gql`
  mutation StripeRemoveAllPaymentMethod($partnerId: String!) {
    removeAllPaymentMethodsStripe(partnerId: $partnerId)
  }
`;

export const StripeSetPaymentMethodAsDefaultMutation = gql`
  mutation StripeSetPaymentMethodAsDefault($partnerId: String!, $paymentMethodId: String!) {
    setPaymentMethodAsDefaultStripe(partnerId: $partnerId, paymentMethodId: $paymentMethodId)
  }
`;

export const StripePurchasetMutation = gql`
  mutation StripePurchase($partnerId: String!, $purchaseType: StripePurchaseType!, $paymentMethodId: String!) {
    purchaseStripe(partnerId: $partnerId, purchaseType: $purchaseType, paymentMethodId: $paymentMethodId) {
      purchaseType
      amount
      currency
    }
  }
`;
