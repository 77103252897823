import { Button, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { pink } from '@mui/material/colors';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AddNewUserModal } from './modals/AddNewUserModal';
import { DeleteUserModal } from './modals/DeleteUserModal';
import {
  PublicUserFragmentFragment,
  useUserManagementPublicUsersQuery,
  useUserManagementRemovePublicUserMutation,
} from 'graphql/types';
import { UserBrand, UserBrandPermission } from 'helpers/userPermissions';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { TableRefreshButton } from 'components/tableRefreshButton/tableRefreshButton';

export const UsersAndPermissionsView: FC = () => {
  const navigate = useNavigate();

  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const {
    data: publicUsersResponse,
    loading: listing,
    refetch: refetchPublicUsers,
  } = useUserManagementPublicUsersQuery();
  const [publicUserRemoveMutation, { loading: removing }] = useUserManagementRemovePublicUserMutation();
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number | null>(null);
  const [changed, setChanged] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allBrands, setAllBrands] = useState<UserBrand[]>([]);
  const [publicUsers, setPublicUsers] = useState<PublicUserFragmentFragment[]>([]);

  useEffect(() => {
    const allBrandsByBrandId = {} as Record<string, UserBrand>;
    const DEFAULT_DATA: PublicUserFragmentFragment[] = [];
    const publicUsersData = publicUsersResponse?.listPublicUsers ?? DEFAULT_DATA;
    publicUsersData.forEach((user) =>
      user.brands.forEach((userBrand) => {
        allBrandsByBrandId[userBrand.publicPartnerId.toLowerCase().trim()] = new UserBrandPermission(userBrand, true);
      })
    );
    const allBrandsData = Object.values(allBrandsByBrandId);
    setAllBrands(allBrandsData);
    setPublicUsers(publicUsersData);
  }, [publicUsersResponse]);

  const handleAddUser = () => {
    setShowAddModal(true);
  };

  const handleDeleteUser = useCallback(async () => {
    if (selectedUserIndex === null) {
      return;
    }

    try {
      const userId = publicUsers[selectedUserIndex].id;
      const result = await publicUserRemoveMutation({
        variables: {
          id: userId,
        },
      });

      if (result && result.data?.removePublicUser === true) {
        refetchPublicUsers();
      }

      setChanged(true);
      setSelectedUserIndex(null);
      createSuccessNotification(`User deleted.`);
    } catch (e) {
      createFailureNotification(`Failed to perform operation. Please contact support. ${e}`);
    }
  }, [
    publicUsers,
    selectedUserIndex,
    publicUserRemoveMutation,
    refetchPublicUsers,
    createFailureNotification,
    createSuccessNotification,
  ]);

  const handleSaveChanges = () => {};

  const selectedUserEmail = useMemo(() => {
    if (selectedUserIndex === null) {
      return '';
    }
    const email = publicUsers[selectedUserIndex].email;
    return email;
  }, [selectedUserIndex, publicUsers]);

  const Columns = useMemo(() => {
    const handleDeleteUserConfirmation = (index: number) => {
      setSelectedUserIndex(index);
      setShowDeleteModal(true);
    };

    const handleSelectPermissions = (index: number) => {
      const publicUser = publicUsers[index];
      navigate('/account/users/brands/', { state: { userId: publicUser.id, brands: allBrands } });
    };

    return [
      {
        label: 'User Email',
        name: 'email',
      },
      {
        label: 'Status',
        name: 'status',
      },
      {
        label: 'Brand Permissions',
        name: 'brandPermissions',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const brands = () => {
              const user = publicUsers[dataIndex];

              const effectiveUserBrands = user.brands.filter(
                (e) => e.isAdmin || e.isBillingAdmin || e.canReadData || e.canManageConnections || e.canBuy
              );
              const original = effectiveUserBrands.map((e) => e.name);
              let searched: string[] = [];
              if (!!searchKey) {
                searched = effectiveUserBrands
                  .filter((e) => e.name.toLowerCase().includes(searchKey.toLowerCase()))
                  .map((e) => e.name);
              }

              let displayed: string[] = [];
              if (searched.length > 0) {
                if (searched.length > 2) {
                  displayed = searched;
                } else {
                  displayed = searched;
                  original.forEach((e) => {
                    if (displayed.length < 3 && !displayed.includes(e)) {
                      displayed = [...displayed, e];
                    }
                  });
                }
              } else {
                displayed = original.slice(0, 3);
              }

              let brandText = '';
              displayed.forEach((name) => {
                brandText += name + ', ';
              });

              if (original.length > displayed.length) {
                brandText = brandText + 'and ' + (original.length - displayed.length).toString() + ' more, ';
              }

              if (brandText === '') {
                return null;
              } else {
                brandText = brandText.substring(0, brandText.length - 2);
                return brandText;
              }
            };

            return (
              <StyledBrandsContainer>
                {brands() && <Typography>{brands()}</Typography>}
                <StyledIconContainer>
                  <StyledRowButton
                    onClick={() => {
                      handleSelectPermissions(dataIndex);
                    }}
                  >
                    Update Permissions
                  </StyledRowButton>
                </StyledIconContainer>
              </StyledBrandsContainer>
            );
          },
        },
      },
      {
        label: 'Actions',
        name: 'delete',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            return (
              <StyledIconContainer>
                <IconButton
                  aria-label="delete"
                  sx={{ color: pink[500] }}
                  title="Delete user permissions"
                  onClick={() => {
                    handleDeleteUserConfirmation(dataIndex);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
    ];
  }, [publicUsers, searchKey, navigate, allBrands]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: 'email',
      direction: 'asc',
    },
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (!col) {
          isFound = true;
        } else {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        }
      });
      return isFound;
    },
    onSearchChange: (searchText) => {
      setSearchKey(searchText);
    },
    customToolbar: () => <TableRefreshButton onClick={() => refetchPublicUsers()} />,
  };

  const getChildren = () => {
    return (
      <>
        <CircularSpinner shown={listing || removing} />
        <Typography
          textAlign={'center'}
          sx={{
            fontSize: 30,
            fontWeight: 800,
            color: '#000000',
          }}
        >
          Users & Permissions
        </Typography>
        <StyledButtonContainer>
          <StyledButton variant={'contained'} disabled={publicUsers.length === 0} onClick={handleAddUser}>
            ADD/INVITE USER
          </StyledButton>
        </StyledButtonContainer>
        <MUIDataTable title="" columns={Columns} data={publicUsers} options={options} />
        <StyledButtonContainer>
          <StyledButton disabled={!changed} variant={'contained'} onClick={handleSaveChanges}>
            SAVE CHANGES
          </StyledButton>
        </StyledButtonContainer>
        <AddNewUserModal
          open={showAddModal}
          brands={allBrands}
          onClose={(reload) => {
            setShowAddModal(false);
            if (reload) {
              refetchPublicUsers();
            }
          }}
        />
        <DeleteUserModal
          open={showDeleteModal}
          user={selectedUserEmail}
          onClose={() => {
            setSelectedUserIndex(null);
            setShowDeleteModal(false);
          }}
          onDelete={() => {
            setShowDeleteModal(false);
            handleDeleteUser();
          }}
        />
      </>
    );
  };

  return <StyledContainer>{getChildren()}</StyledContainer>;
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 32px;
`;

const StyledBrandsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
  min-width: 96px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
});

const StyledRowButton = styled(Button)({
  textTransform: 'none',
  textDecoration: 'underline',
  fontSize: '14px',
  fontWeight: '400',
  paddingLeft: 0,
});
