import { Box, Button, LinearProgress, Tooltip, Typography } from '@mui/material';
import { BackfillStatus, BackfillStatusDetail, BackfillStatusPeriodDetail } from 'helpers/userPermissions';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { BackfillStatusRequestedByType, usePpListBackfillsQuery } from 'graphql/types';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackfillStatusDetailModal } from './modals/BackfillStatusDetailModal';
import { gql } from '@apollo/client';
import { useAppSelector } from 'store/hooks';
import { TimeAgoTypograph } from 'components/nodeView/parts/TimeAgoTypography';
import { LineProgressBar } from 'components/progressbar/LineProgressBar';
import { TableRefreshButton } from 'components/tableRefreshButton/tableRefreshButton';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

export const BackfillStatusView: FC = () => {
  const publicPartnerId = useAppSelector((state) => state.app.partnerId);
  const connectionName = useAppSelector((state) => state.app.partnerName);
  const partnerUUID = useAppSelector((state) => state.app.partnerUUID);
  const [data, setData] = useState<BackfillStatus[]>([]);
  const { data: backfillData, loading, refetch: reloadData } = usePpListBackfillsQuery({
    variables: {
      publicPartnerId: publicPartnerId ?? 'none',
    },
  });
  const [selectedEntry, setSelectedEntry] = useState<BackfillStatus>();
  const [filterRequestedBy, setFilterRequestedBy] = useState<BackfillStatusRequestedByType | null>(BackfillStatusRequestedByType.User);
  const [openDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    const data = (backfillData?.listBackfills ?? []).map((b) => ({
      id: b.id,
      started: b.startedAt,
      lastUpdated: b.lastUpdatedOn,
      requester: b.requesterEmail,
      requestedBy: b.requestedBy,
      status: b.status,
      progress: b.percentageCompletion ?? null,
      reports: b.reports.map((r) => ({
        description: r.description || '',
        friendlyName: r.reportName,
        periods: r.requestedPeriods.map((p) => {
          const fromDate = p.backfillStartDate ? new Date(Date.parse(p.backfillStartDate)).toLocaleString() : '';
          const toDate = p.backfillEndDate ? new Date(Date.parse(p.backfillEndDate)).toLocaleString() : '';

          return {
            period: p.period, fromDate, toDate
          } as BackfillStatusPeriodDetail;
        }),
        table: r.outputTableName,
        schema: r.outputSchemaType,
        reasonAutomationHelpUrl: r.publicSchema?.reasonAutomationHelpUrl || '',
      } as BackfillStatusDetail))
    } as BackfillStatus));
    setData(data);
  }, [backfillData]);

  const Columns = useMemo(() => {
    const handleDetails = (index: number) => {
      setSelectedEntry(data[index]);
      setOpenDetails(true);
    };

    return [
      {
        label: 'Id',
        name: 'id',
        options: {
          display: false,
        }
      },
      {
        label: 'Requested By',
        name: 'requestedBy',
        options: {
          filter: true,
          filterList: filterRequestedBy ? ['USER'] : [],
          customFilterListOptions: {
            render: (v) => `Requested by: ${v}`,
          }
        }
      },
      {
        label: 'Requester',
        name: 'requester',
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];

            switch (record.status) {
              case 'IN_PROGRESS':
                // return <Typography sx={{ fontSize: 14 }}>⚒️ in progress</Typography>;
                return !record.progress ? (<Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
                ) : (
                  <LineProgressBar value={record.progress} showDigits={1} />);

              case 'FAILED':
                return <Tooltip title={`Please contact support indicating backfill id: ${record.id}.`}><Typography sx={{ fontSize: 14, color: '#550000' }}>⚠️ failed</Typography></Tooltip>;

              case 'COMPLETED':
                return <Typography sx={{ fontSize: 14, color: '#005500' }}>✅ completed</Typography>;

              case 'QUEUED':
                // return <Typography sx={{ fontSize: 14, color: '#555555' }}>⌛ queued</Typography>;
                return <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>;

              default:
                return <Typography sx={{ fontSize: 14 }}>{record.status}</Typography>;
            }
          },
        },
      },
      {
        label: 'Started',
        name: 'started',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];
            const friendlyDateTime = new Date(Date.parse(record.started)).toLocaleString();
            return (
              <TimeAgoTypograph isoTimeString={record.started} title={friendlyDateTime} />
            )
          }
        }
      },
      {
        label: 'Last Updated',
        name: 'lastUpdated',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];
            const friendlyDateTime = new Date(Date.parse(record.lastUpdated)).toLocaleString();
            return (
              <TimeAgoTypograph isoTimeString={record.lastUpdated} title={friendlyDateTime} />
            )
          }
        }
      },
      {
        label: 'Reports',
        name: 'reports',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];

            if (!record?.reports?.length) {
              return <Typography sx={{ fontSize: 14 }}>ALL</Typography>
            } else {
              return (
                <>
                  {record?.reports?.map((r, rI) => (<ReportLabel style={{ paddingRight: 4 }} key={`${record.id}-${rI}`} title={`TABLE: ${r.schema?.trim() ? `${r.schema.trim().toLowerCase()}.` : ''}${r.table}`}>{r.friendlyName || r.table}</ReportLabel>))}
                </>
              );
            }
          },
        },
      },
      {
        label: 'Details',
        name: 'details',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];
            return (
              <Button disabled={!record?.reports?.length} sx={{ paddingLeft: 0 }} onClick={() => handleDetails(dataIndex)}>
                Details
              </Button>
            );
          },
        },
      },
    ] as MUIDataTableColumnDef[];
  }, [data, filterRequestedBy]);

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    onFilterChipClose: (_index: number, removedFilter: string) => {
      if (removedFilter === filterRequestedBy) {
        setFilterRequestedBy(null);
      }
    },
    sortOrder: {
      name: 'lastUpdated',
      direction: 'desc',
    },
    customToolbar: () => <TableRefreshButton onClick={() => reloadData({ publicPartnerId: publicPartnerId ?? 'none' })} />,
    textLabels: {
      body: {
        noMatch: loading ? 'Loading backfill data. Please wait...' : 'No purchased backfills found. Use the Tables tab to purchase backfills.',
      }
    }
  };

  return (
    <StyledContainer>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}
      >
        Backfill Status
      </Typography>
      <CircularSpinner shown={loading} />
      <MUIDataTable title={!connectionName || !partnerUUID ? '' : `API Connection: ${connectionName} [${partnerUUID}]`} columns={Columns} data={data} options={options} />
      {selectedEntry && openDetails && (
        <BackfillStatusDetailModal
          open={openDetails}
          entry={selectedEntry}
          onClose={() => {
            setOpenDetails(false);
            setSelectedEntry(undefined);
          }}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 32px;
`;

const ReportLabel = styled(Typography)({
  textDecoration: 'underline',
  fontSize: 14,
});

export const pp_listBackfills = gql`
  query pp_listBackfills($publicPartnerId: String!) {
    listBackfills(
      publicPartnerId: $publicPartnerId
    ) {
      id
      status
      percentageCompletion
      startedAt
      lastUpdatedOn
      requesterEmail
      requestedBy
      reports
      {
        id
        reportName
        description
        outputTableName
        outputSchemaType
        publicSchema {
          reasonAutomationHelpUrl
        }
        requestedPeriods
        {
          id
          period
          backfillStartDate
          backfillEndDate
        }
      }
    }
  }
`;