import { Typography, IconButton, Button, Checkbox } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { BackfillOrderSource, DataTableSource, DataTableSourceCheckType } from 'helpers/userPermissions';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Warning } from '@mui/icons-material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { green, grey } from '@mui/material/colors';
import { BackfillOrderConfirmPurchaseModal } from './BackfillOrderConfirmPurchaseModal';
import {
  PublicSchemaReportPeriodAvailableHistoryUnit,
  usePpDataTablesWithBackfillsAvailableQuery,
} from 'graphql/types';
import { useAppSelector } from 'store/hooks';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { capitalizeFirstLetterOfEachWord } from 'helpers/strings';

type BackfillOrderModalProps = {
  open: boolean;
  selectedEntry: DataTableSource;
  onClose: () => void;
  onSubmit: () => void;
};

export const BackfillOrderModal: FC<BackfillOrderModalProps> = ({ open, onClose, onSubmit, selectedEntry }) => {
  const publicPartnerId = useAppSelector((state) => state.app.partnerId);
  const windowWidth = window.innerWidth;

  const { data: tablesBackfillData, loading } = usePpDataTablesWithBackfillsAvailableQuery({
    variables: {
      publicPartnerId: publicPartnerId ?? 'none',
    },
  });
  const [data, setData] = useState<BackfillOrderSource[]>([]);
  const [selectedEntries, setSelectedEntries] = useState<BackfillOrderSource[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [openConfirm, setOpenConfirm] = useState(false);

  useMemo(() => {
    setData([]);
    setSelectedEntries([]);
  }, []);

  useEffect(() => {
    function _durationPrint(bP: {
      availableHistoryRange?: number | null | undefined;
      availableHistoryRangeUnit?: PublicSchemaReportPeriodAvailableHistoryUnit | null | undefined;
    }) {
      if (!bP?.availableHistoryRange || !bP?.availableHistoryRangeUnit) {
        return '';
      }

      const numberString = bP.availableHistoryRange === 0 ? 'no' : bP.availableHistoryRange;
      switch (bP.availableHistoryRangeUnit) {
        case PublicSchemaReportPeriodAvailableHistoryUnit.Days:
          return `${numberString} ${bP.availableHistoryRange === 1 ? 'day' : 'days'}`;
        case PublicSchemaReportPeriodAvailableHistoryUnit.Weeks:
          return `${numberString} ${bP.availableHistoryRange === 1 ? 'week' : 'weeks'}`;
        case PublicSchemaReportPeriodAvailableHistoryUnit.Months:
          return `${numberString} ${bP.availableHistoryRange === 1 ? 'month' : 'months'}`;
        case PublicSchemaReportPeriodAvailableHistoryUnit.Years:
          return `${numberString} ${bP.availableHistoryRange === 1 ? 'year' : 'years'}`;
        default:
          return '';
      }
    }

    const data = (tablesBackfillData?.listDataTables ?? []).map((tBD) => {
      // TODO LATER: offer user selection for which backfill to purchase
      const backfillEntry = tBD?.backfillsAvailable?.[0];
      const canBackfill = !!backfillEntry && (backfillEntry?.priceAmount ?? -1) > 0;
      const isSelected = canBackfill && tBD.outputTableName === selectedEntry?.table;
      const availableHistory =
        backfillEntry?.backfillPeriods
          ?.map((bP) => _durationPrint(bP))
          ?.join(', ') ?? '';
      let backfill: DataTableSourceCheckType;
      if (isSelected) {
        backfill = DataTableSourceCheckType.checked;
      } else if (canBackfill) {
        backfill = DataTableSourceCheckType.unchecked;
      } else {
        backfill = DataTableSourceCheckType.warning;
      }
      let price = '';
      if (canBackfill && backfillEntry.priceAmount) {
        price = `${backfillEntry.priceCurrency === 'USD' ? '$' : backfillEntry.priceCurrency} ${(
          backfillEntry.priceAmount / 100
        ).toFixed(2)}`;
      }
      return {
        availableHistory,
        backfill,
        price,
        priceInCents: canBackfill ? backfillEntry.priceAmount : 0,
        priceCurrency: backfillEntry?.priceCurrency || '',
        friendlyName: tBD.publicSchema?.name ?? '',
        periods: tBD.publicSchema?.periods?.map((p) => capitalizeFirstLetterOfEachWord(p.period)).join(', ') ?? '',
        table: tBD.outputTableName,
        record: tBD,
        backfillOrdered: backfillEntry,
      } as BackfillOrderSource;
    });
    setData(data);
  }, [tablesBackfillData, selectedEntry]);

  useEffect(() => {
    const selectedDataEntries = (data ?? []).filter((d) => d.backfill === DataTableSourceCheckType.checked);
    setSelectedEntries(selectedDataEntries);
    let newTotalPrice = 0;
    selectedDataEntries.forEach((d) => {
      newTotalPrice += d.priceInCents;
    });

    setTotalPrice(newTotalPrice / 100);
  }, [data]);

  const handleClickSubmit = () => {
    setOpenConfirm(true);
  };

  const handleStart = () => {
    setOpenConfirm(false);
    onSubmit();
  };

  const Columns = useMemo(() => {
    const handleCheck = (index: number) => {
      const newData = [...data];
      switch (newData[index].backfill) {
        case DataTableSourceCheckType.checked:
          newData[index].backfill = DataTableSourceCheckType.unchecked;
          break;

        case DataTableSourceCheckType.unchecked:
          newData[index].backfill = DataTableSourceCheckType.checked;
          break;

        default:
        case DataTableSourceCheckType.warning:
          break;
      }
      setData(newData);
    };

    return [
      {
        label: 'Table',
        name: 'table',
      },
      {
        label: 'Friendly Name',
        name: 'friendlyName',
      },
      {
        label: 'Periods',
        name: 'periods',
      },
      {
        label: 'Available History',
        name: 'availableHistory',
      },
      {
        label: 'Backfill?',
        name: 'backfill',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const checkType = data[dataIndex].backfill;

            const childIcon = () => {
              switch (checkType) {
                case DataTableSourceCheckType.checked:
                  return <Checkbox checked={true} disabled={false} color={'success'} />;
                case DataTableSourceCheckType.unchecked:
                  return <Checkbox checked={false} disabled={true} color={'default'} />;
                case DataTableSourceCheckType.warning:
                  return (
                    <div style={{ padding: '8px' }}>
                      <Warning />
                    </div>
                  );
              }
            };
            let color = '#000';
            if (checkType === DataTableSourceCheckType.checked) {
              color = green[500];
            } else if (checkType === DataTableSourceCheckType.unchecked) {
              color = grey[600];
            }

            return (
              <StyledIconContainer>
                <IconButton
                  aria-label="user-admin"
                  sx={{ color: color }}
                  disabled={checkType === DataTableSourceCheckType.warning}
                  onClick={() => handleCheck(dataIndex)}
                >
                  {childIcon()}
                </IconButton>
              </StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Price',
        name: 'price',
      },
    ];
  }, [data]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    tableBodyMaxHeight: '442px',
    sortOrder: {
      name: 'table',
      direction: 'asc',
    },
    textLabels: {
      body: {
        noMatch: 'Loading all tables, please wait.'
      }
    }
  };

  // height computation --
  const modelHeight = Math.min(760, (data?.length ?? 0) * 100 + 69 + 53 + 36 + 290);

  return (
    <Modal
      width={windowWidth - 480}
      height={modelHeight}
      open={open}
      onClickOutside={onClose}
      title="Historical Data Backfill Tool"
    >
      <CircularSpinner shown={loading} />
      <StyledContainer>
        <StyledLabelContainer>
          <StyledNormalTypography>Use this tool to </StyledNormalTypography>
          <StyledBoldTypography>backfill historical data from Amazon into your database.</StyledBoldTypography>
        </StyledLabelContainer>
        <MUIDataTable title="" columns={Columns} data={data} options={options} />
        <StyledRightContainer>
          <StyledPriceContainer style={{ width: 280, marginRight: 80, marginTop: 12 }}>
            <StyledBoldTypography style={{ fontSize: 16 }}>Total price:</StyledBoldTypography>
            <StyledBoldTypography style={{ fontSize: 16 }}>
              {totalPrice > 0 ? `$ ${totalPrice.toFixed(2)}` : '--'}
            </StyledBoldTypography>
          </StyledPriceContainer>
        </StyledRightContainer>
        <StyledButtonContainer>
          <StyledSubmitButton variant={'contained'} disabled={!selectedEntries?.length} onClick={handleClickSubmit}>
            SUBMIT BACKFILL ORDER
          </StyledSubmitButton>
        </StyledButtonContainer>
        {selectedEntries.length && (
          <BackfillOrderConfirmPurchaseModal
            open={openConfirm}
            onClose={() => {
              setOpenConfirm(false);
            }}
            onPurchase={handleStart}
            cartItems={selectedEntries}
            totalPriceAsString={`$ ${totalPrice.toFixed(2)}`}
          />
        )}
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  overflow-y: hidden;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 4px;
  padding-bottom: 8px;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const StyledBoldTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
});

const StyledIconContainer = styled.div`
  align-items: left;
  display: flex;
  gap: 8px;
`;

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledRightContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 0;
`;

const StyledSubmitButton = styled(Button)({
  textTransform: 'none',
  width: '300px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
  marginTop: 24,
});
