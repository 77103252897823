import { FC, useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { gql } from '@apollo/client';
import { BackfillOrderSource } from 'helpers/userPermissions';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { PublicPartnerDataTableBackfillInput, PublicSchemaReportPeriodInput, usePpBackfillPurchaseMutation } from 'graphql/types';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { useAppSelector } from 'store/hooks';

type BackfillOrderConfirmPurchaseModalProps = {
  open: boolean;
  totalPriceAsString: string;
  cartItems: BackfillOrderSource[];
  onClose: () => void;
  onPurchase: () => void;
};

export const BackfillOrderConfirmPurchaseModal: FC<BackfillOrderConfirmPurchaseModalProps> = ({
  open,
  onClose,
  onPurchase,
  totalPriceAsString,
  cartItems,
}) => {
  const publicPartnerId = useAppSelector((state) => state.app.partnerId);
  const { createSuccessNotification, createFailureNotification } = useMuiNotifications();
  const [purchaseBackfills, { loading: purchasingGql }] =
    usePpBackfillPurchaseMutation();
  const [purchasingFlow, setPurchasingFlow] = useState(false);
  const style = {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 440,
    bgcolor: '#333',
    border: '2px solid #333',
    boxShadow: 24,
    p: 4,
  };

  const handlePurchase = async () => {
    setPurchasingFlow(true);
    let success = true;
    try {
      const backfillsToBePurchased = cartItems.map((cI) => {
        if (!cI?.backfillOrdered) {
          throw new Error('invalid backfill items found!');
        }

        return {
          priceAmount: cI.backfillOrdered.priceAmount,
          priceCurrency: cI.backfillOrdered.priceCurrency,
          backfillPeriods: cI.backfillOrdered.backfillPeriods.map((p) => ({
            id: p.id,
            allowsBackfill: p.allowsBackfill,
            availableHistoryRange: p.availableHistoryRange,
            availableHistoryRangeUnit: p.availableHistoryRangeUnit,
            period: p.period,
            updateFrequency: p.updateFrequency,
          } as PublicSchemaReportPeriodInput)),
        } as PublicPartnerDataTableBackfillInput;
      });
      const result = await purchaseBackfills({
        variables: {
          publicPartnerId: publicPartnerId || '',
          backfillsToBePurchased,
        },
      });
      if (!result.data?.purchaseBackfills) {
        success = false;
      }
    } catch (e) {
      let errorMessage = `Failed to purchase backfills. Please try again and contact support.`;
      if (`${e}`.includes('No payment method on file')) {
        errorMessage = `No payment method on file. Please add a Default payment method in the Billing tab or request your billing administrator to do so.`;
      }
      createFailureNotification(`${errorMessage}`, 15000);
      success = false;
    }
    setPurchasingFlow(false);

    if (success) {
      createSuccessNotification('Successfully purchased backfills. Please go to Backfills tab to track progress of the order.', 15000);
      onPurchase();
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <CircularSpinner shown={purchasingGql || purchasingFlow} message='Purchasing' />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: '#fff', marginBottom: 2, textAlign: 'center' }}
        >
          Confirm purchase
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: '#fff', marginBottom: 4, textAlign: 'center' }}
        >
          {totalPriceAsString}
        </Typography>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          Your {cartItems.length} backfill{cartItems.length === 1 ? '' : 's'} will be billed and begin immediately after
          submitting. We are unable to cancel or refund backfill orders.
        </MessageLabel>
        <StyledButtonContainer>
          <StyledButton variant={'contained'} onClick={handlePurchase}>
            PURCHASE
          </StyledButton>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

const MessageLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '4px',
  color: '#fff',
});

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
  marginTop: 48,
});

export const pp_dataTables_withBackfillsAvailable = gql`
  query pp_dataTables_withBackfillsAvailable($publicPartnerId: String!) {
    listDataTables(publicPartnerId: $publicPartnerId) {
      id
      reportStatus
      dataStatus
      outputSchemaType
      outputTableName
      tableLogs {
        id
        writeSinkSlug
        dataStatus
        lastIngestedOn
        lastFailedOn
        lastSucceededOn
        numberRowsCreated
        numberRowsUpdated
      }
      publicSchema {
        id
        name
        description
        portalType
        outputTableName
        outputSchemaName
        allowsBackfill
        updateFrequency
        periods { id period availableHistoryRange availableHistoryRangeUnit allowsBackfill updateFrequency }
      }

      backfillsAvailable { backfillPeriods { id period availableHistoryRange availableHistoryRangeUnit allowsBackfill updateFrequency } priceCurrency priceAmount }

      # tableLogs { id writeSinkSlug dataStatus lastIngestedOn lastFailedOn lastSucceededOn numberRowsCreated numberRowsUpdated }
    }
  }
`;

export const pp_backfill_purchase = gql`
  mutation pp_backfill_purchase($publicPartnerId: String!, $backfillsToBePurchased: [PublicPartnerDataTableBackfillInput!]!) {
    purchaseBackfills (
      publicPartnerId: $publicPartnerId,
      backfillsToBePurchased: $backfillsToBePurchased
    )
  }
`;
