import { FC } from 'react';
import styled from 'styled-components';
import { OnboardingChecklistComponent } from './OnboardingChecklistComponent';
import { OnboardingQuickLinksComponent } from './OnboardingQuickLinksComponent';

export const InstructionsComponent: FC = () => {
  return (
    <StyledContainer>
      <OnboardingChecklistComponent />
      <OnboardingQuickLinksComponent />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 100px;
`;
