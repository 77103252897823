import { Button, Checkbox, IconButton, Typography } from '@mui/material';
import { DataTableSource, DataTableSourceCheckType } from 'helpers/userPermissions';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { green, grey } from '@mui/material/colors';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Warning } from '@mui/icons-material';
import { SaveDataSubscriptionModal } from './modals/SaveDataSubscriptionModal';
import { MissingPermissionsModal } from './modals/MissingPermissionsModal';
import { BackfillOrderModal } from './modals/BackfillOrderModal';
import { TableRefreshButton } from 'components/tableRefreshButton/tableRefreshButton';
import { useAppSelector } from 'store/hooks';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { gql } from '@apollo/client';
import { PublicPartnerDataTableReportStatus, usePpDataTablesNoBackfillsAvailableQuery } from 'graphql/types';
import { capitalizeFirstLetterOfEachWord } from 'helpers/strings';
import { ReportHelpRedirectLink } from 'components/reportHelpRedirectLink/reportHelpRedirectLink';
import { useMuiNotifications } from 'context/muiNotificationContext';

export const DataTablesView: FC = () => {
  const { createWarningNotification } = useMuiNotifications();
  const publicPartnerId = useAppSelector((state) => state.app.partnerId);
  const connectionName = useAppSelector((state) => state.app.partnerName);
  const partnerUUID = useAppSelector((state) => state.app.partnerUUID);
  const { data: tablesData, loading, refetch: reloadData } = usePpDataTablesNoBackfillsAvailableQuery({
    variables: {
      publicPartnerId: publicPartnerId ?? 'none',
    },
  });
  const [data, setData] = useState<DataTableSource[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<DataTableSource>();
  const [changed, setChanged] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openMissingPermission, setOpenMissingPermission] = useState(false);
  const [openBackfill, setOpenBackfill] = useState(false);

  const DisabledColor = grey[300];

  useEffect(() => {
    const data = (tablesData?.listDataTables ?? []).map((tD) => {
      let checkType: DataTableSourceCheckType;
      switch (tD.reportStatus) {
        case PublicPartnerDataTableReportStatus.Enabled:
          checkType = DataTableSourceCheckType.checked;
          break;

        case PublicPartnerDataTableReportStatus.NeedConnectionCredentials:
          checkType = DataTableSourceCheckType.warning;
          break;

        case PublicPartnerDataTableReportStatus.DisabledNeedConnection:
        case PublicPartnerDataTableReportStatus.DisabledCanBeEnabled:
          checkType = DataTableSourceCheckType.unchecked;
          break;

        case PublicPartnerDataTableReportStatus.Disabled:
        default:
          checkType = DataTableSourceCheckType.disabled;
          break;
      }

      return {
        checkType,
        table: tD.outputTableName,
        schemaType: tD.outputSchemaType,
        enableBackfill: !!tD.publicSchema,
        friendlyName: tD.publicSchema?.name ?? '',
        updateFrequency: capitalizeFirstLetterOfEachWord(tD.publicSchema?.updateFrequency ?? ''),
        periods: tD.publicSchema?.periods?.map((p) => capitalizeFirstLetterOfEachWord(p.period)).join(', ') ?? '',
        record: tD,
        reasonAutomationHelpUrl: tD.publicSchema?.reasonAutomationHelpUrl || '',
      } as DataTableSource;
    });
    setData(data);
  }, [tablesData]);

  const handleSaveChanges = () => {
    setOpenSave(false);
  };

  const handleClickSave = () => {
    setOpenSave(true);
  };

  const Columns = useMemo(() => {
    const handleCheck = (index: number) => {
      const newItem = data[index];
      if (newItem.checkType === DataTableSourceCheckType.disabled) {
        createWarningNotification(`This report cannot be enabled at this time. Please contact support for assistance.`)
        return;
      } else if (newItem.checkType === DataTableSourceCheckType.warning) {
        createWarningNotification(`This report cannot be enabled at this time. Please go to the Home tab and ensure the API connection is connected.`)
        return;
      } else if (newItem.checkType === DataTableSourceCheckType.unchecked &&
        newItem.record.reportStatus === PublicPartnerDataTableReportStatus.DisabledNeedConnection) {
        setOpenMissingPermission(true);
      } else {
        newItem.checkType =
          newItem.checkType === DataTableSourceCheckType.checked
            ? DataTableSourceCheckType.unchecked
            : DataTableSourceCheckType.checked;
        const newData = data.map((item, itemIndex) => (itemIndex === index ? { ...item, ...newItem } : item));
        setData(newData);
        setChanged(true);
      }
    };

    const handleBackfill = (index: number) => {
      setOpenBackfill(true);
      setSelectedEntry(data[index]);
    };

    return [
      {
        label: 'Is Enabled?',
        name: 'checked',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];
            const checkType = record.checkType;

            const childIcon = () => {
              switch (checkType) {
                case DataTableSourceCheckType.checked:
                  return (<IconButton
                    sx={{ color: green[500] }}
                    title="Disable report"
                    onClick={() => {
                      handleCheck(dataIndex);
                    }}
                  >
                    <Checkbox checked color='success' />
                  </IconButton>);
                case DataTableSourceCheckType.unchecked:
                  return (<IconButton
                    sx={{ color: grey[500] }}
                    title="Enable report"
                    onClick={() => {
                      handleCheck(dataIndex);
                    }}
                  >
                    <Checkbox color='default' />
                  </IconButton>);
                // return <Icon />;
                // return <Checkbox checked={checked} color={checked ? 'success' : 'default'} disabled={checked === false} />
                case DataTableSourceCheckType.warning:
                  return <IconButton><Warning /></IconButton>;
                case DataTableSourceCheckType.disabled:
                  return (<IconButton
                    sx={{ color: DisabledColor }}
                    title="Unable to add report. Please contact support."
                    onClick={() => {
                      handleCheck(dataIndex);
                    }}
                  >
                    <Checkbox color='default' sx={{ color: DisabledColor }} disabled={true} />
                  </IconButton>);
              }
            };

            return (
              <StyledIconContainer>{childIcon()}</StyledIconContainer>
            );
          },
        },
      },
      {
        label: 'Table',
        name: 'table',
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];
            const checkType = record.checkType;

            const color = checkType === DataTableSourceCheckType.disabled ? DisabledColor : undefined;

            return (<Typography color={color}>{record.table}</Typography>);
          },
        }
      },
      {
        label: 'Friendly Name',
        name: 'friendlyName',
      },
      {
        label: 'Update Frequency',
        name: 'updateFrequency',
      },
      {
        label: 'Periods',
        name: 'periods',
      },
      {
        label: 'Description',
        name: 'description',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];

            return <ReportHelpRedirectLink schemaType={record.schemaType} tableName={record.table} reportHelpUrl={record.reasonAutomationHelpUrl} />
          },
        },
      },
      {
        label: 'Actions',
        name: 'actions',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];
            const enabled = data[dataIndex].enableBackfill;
            const showBackfill = record.checkType === DataTableSourceCheckType.disabled ? false : true;
            const disabledBackfill = !enabled;

            return (
              <>
                {
                  showBackfill ? <StyledBackfillButton
                    disabled={disabledBackfill}
                    variant={'contained'}
                    onClick={() => {
                      handleBackfill(dataIndex);
                    }}
                    sx={{ pointerEvents: 'auto!important' as any }}
                    title={enabled ? `Perform a backfill operation` : `⚠️ Unable to perform a backfill operation. Please contact support.`}
                  >
                    BACKFILL
                  </StyledBackfillButton> : undefined
                }
              </>
            );
          },
        },
      },
    ] as MUIDataTableColumnDef[];
  }, [DisabledColor, createWarningNotification, data]);

  const windowHeight = window.innerHeight;
  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    print: false,
    download: false,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: 'table',
      direction: 'asc',
    },
    customToolbar: () => (
      <TableRefreshButton
        onClick={() => reloadData({ publicPartnerId: publicPartnerId ?? 'none' })}
      />
    ),
    textLabels: {
      body: {
        noMatch: 'Loading all tables, please wait.'
      }
    },
    // pagination: false,
    tableBodyMaxHeight: `${windowHeight - 450}px`,
  };

  return (
    <StyledContainer>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}
      >
        Tables
      </Typography>
      <CircularSpinner shown={loading} />
      <MUIDataTable
        title={!connectionName || !partnerUUID ? '' : `API Connection: ${connectionName} [${partnerUUID}]`}
        columns={Columns}
        data={data}
        options={options}
      />
      <StyledButtonContainer>
        <StyledButton disabled={!changed} variant={'contained'} onClick={handleClickSave}>
          SAVE CHANGES
        </StyledButton>
      </StyledButtonContainer>
      <SaveDataSubscriptionModal
        open={openSave}
        onSave={handleSaveChanges}
        onCancel={() => {
          setOpenSave(false);
        }}
      />
      <MissingPermissionsModal
        open={openMissingPermission}
        onOK={() => setOpenMissingPermission(false)}
        onContact={() => {
          setOpenMissingPermission(false);
        }}
      />
      {openBackfill && selectedEntry && <BackfillOrderModal
        open={openBackfill}
        selectedEntry={selectedEntry}
        onClose={() => {
          setOpenBackfill(false);
          setSelectedEntry(undefined);
        }}
        onSubmit={() => {
          setOpenBackfill(false);
          setSelectedEntry(undefined);
        }}
      />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 32px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const StyledButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
});

const StyledIconContainer = styled.div`
  align-items: left;
  display: flex;
  gap: 8px;
`;

const StyledBackfillButton = styled(Button)({
  textTransform: 'none',
  width: '80px',
  height: '32px',
  backgroundColor: '#23A4A4',
});

export const pp_dataTables_noBackfillsAvailable = gql`
  query pp_dataTables_noBackfillsAvailable($publicPartnerId: String!) {
    listDataTables(
      publicPartnerId: $publicPartnerId
    ) {
    id
    reportStatus
    dataStatus
    outputSchemaType
    outputTableName
    tableLogs {
      id
      writeSinkSlug
      dataStatus
      lastIngestedOn
      lastFailedOn
      lastSucceededOn
      numberRowsCreated
      numberRowsUpdated
    }
    publicSchema {
      id
      name
      description
      portalType
      outputTableName
      outputSchemaName
      allowsBackfill
      updateFrequency
      reasonAutomationHelpUrl
      periods { id period availableHistoryRange availableHistoryRangeUnit allowsBackfill updateFrequency }
    }

    # comment as needed
    # backfillsAvailable { backfillPeriods { id period availableHistoryRange availableHistoryRangeUnit allowsBackfill updateFrequency } priceCurrency priceAmount }

    tableLogs { id writeSinkSlug dataStatus lastIngestedOn lastFailedOn lastSucceededOn numberRowsCreated numberRowsUpdated }
    }
  }
`;