import { Button, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { FC } from 'react';
import styled from 'styled-components';

type SaveDataSubscriptionModalProps = {
  open: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const SaveDataSubscriptionModal: FC<SaveDataSubscriptionModalProps> = ({ open, onSave, onCancel }) => {

  return (
    <Modal width={640} height={480} open={open} onClickOutside={onCancel} title='Save changes'>
      <StyledContainer>
        <StyledNormalTypography>
          You are making the following changes to your data subscription for [BRAND].
        </StyledNormalTypography>
        <StyledCenteredContainer style={{ marginTop: 36 }}>
          <StyledLabelContainer>
            <StyledNormalTypography>
              [Added table] -------------------------------------------------------
            </StyledNormalTypography>
            <StyledNormalTypography>
              +$20.00/mo
            </StyledNormalTypography>
          </StyledLabelContainer>
        </StyledCenteredContainer>
        <StyledCenteredContainer>
          <StyledLabelContainer>
            <StyledNormalTypography>
              [Removed table] --------------------------------------------------
            </StyledNormalTypography>
            <StyledNormalTypography>
              -$12.00/mo
            </StyledNormalTypography>
          </StyledLabelContainer>
        </StyledCenteredContainer>
        <StyledCenteredContainer>
          <StyledLabelContainer>
            <StyledBoldTypography>
              Change to your monthly subscription:
            </StyledBoldTypography>
            <StyledBoldTypography>
              +$8.00/mo
            </StyledBoldTypography>
          </StyledLabelContainer>
        </StyledCenteredContainer>
        <StyledCenteredContainer>
          <StyledLabelContainer>
            <StyledBoldTypography>
              New monthly subscription cost:
            </StyledBoldTypography>
            <StyledBoldTypography>
              +$237.00/mo
            </StyledBoldTypography>
          </StyledLabelContainer>
        </StyledCenteredContainer>
        <StyledCenteredContainer>
          <StyledSaveButton variant={'contained'} onClick={onSave}>
            Save
          </StyledSaveButton>
        </StyledCenteredContainer>
        <StyledCenteredContainer>
          <StyledCancelButton onClick={onCancel}>
            Cancel
          </StyledCancelButton>
        </StyledCenteredContainer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  gap: 16px;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-inline: 50px;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
});

const StyledBoldTypography = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
});

const StyledCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSaveButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
  marginTop: 24,
});

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '200px',
  height: '48px',
  color: '#000',
  fontSize: 16,
  marginBottom: 'auto',
});