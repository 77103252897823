import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SetupIntent } from '@stripe/stripe-js';

export type UserAccessLevelType = 'MANAGE_DATA' | 'ADMIN' | 'BILLING_ADMIN' | 'ACCOUNT_USERS_AND_PERMISSIONS';

export type AppState = {
  partnerId: string | null; // if there are one more partner provided, this value would be partner id selected thing by user.
  partnerName: string | null; // if there are one more partner provided, this value would be partner name selected thing by user.
  partnerUUID: string | null;
  reloadManageNodes: boolean; // Set true if need to reload `listNodesData` anywhere, once it's called, then set false.
  selectedNodeName: string | null;
  selectedNodeData: string | null;
  userAccessLevels: UserAccessLevelType[];
  stripeClientSecret: string | null;
  reloadStripeClientSecret: boolean;
  paymentMethodSetDefault: boolean;
  paymentMethodAddingStatus: SetupIntent.Status | null;
  paymentMethodAddingMethodId: string | null;
  reloadPaymentMethods: boolean;
  savingBillingInformation: boolean;
};

export const initialState: AppState = {
  partnerId: null,
  partnerName: null,
  partnerUUID: null,
  reloadManageNodes: false,
  selectedNodeName: null,
  selectedNodeData: null,
  userAccessLevels: [],
  stripeClientSecret: null,
  reloadStripeClientSecret: false,
  paymentMethodSetDefault: false,
  paymentMethodAddingStatus: null,
  paymentMethodAddingMethodId: null,
  reloadPaymentMethods: false,
  savingBillingInformation: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPartnerId(state, action: PayloadAction<string | null>) {
      state.partnerId = action.payload;
    },
    setPartnerName(state, action: PayloadAction<string | null>) {
      state.partnerName = action.payload;
    },
    setPartnerUUID(state, action: PayloadAction<string | null>) {
      state.partnerUUID = action.payload;
    },
    setReloadManageNodes(state, action: PayloadAction<boolean>) {
      state.reloadManageNodes = action.payload;
    },
    setSelectedNodeName(state, action: PayloadAction<string | null>) {
      state.selectedNodeName = action.payload;
    },
    setSelectedNodeData(state, action: PayloadAction<string | null>) {
      state.selectedNodeData = action.payload;
    },
    addToUserAccessLevels(state, action: PayloadAction<UserAccessLevelType | null>) {
      if (action.payload && !state.userAccessLevels.includes(action.payload)) {
        state.userAccessLevels.push(action.payload);
      }
    },
    clearUserAccessLevels(state, action: PayloadAction<null>) {
      state.userAccessLevels = [];
    },
    setStripeClientSecret(state, action: PayloadAction<string | null>) {
      state.stripeClientSecret = action.payload;
    },
    setReloadStripeClientSecret(state, action: PayloadAction<boolean>) {
      state.reloadStripeClientSecret = action.payload;
    },
    setPaymentMethodSetDefault(state, action: PayloadAction<boolean>) {
      state.paymentMethodSetDefault = action.payload;
    },
    setPaymentMethodAddingStatus(state, action: PayloadAction<SetupIntent.Status | null>) {
      state.paymentMethodAddingStatus = action.payload;
    },
    setPaymentMethodAddingMethodId(state, action: PayloadAction<string | null>) {
      state.paymentMethodAddingMethodId = action.payload;
    },
    setReloadPaymentMethods(state, action: PayloadAction<boolean>) {
      state.reloadPaymentMethods = action.payload;
    },
    setSavingBillingInformation(state, action: PayloadAction<boolean>) {
      state.savingBillingInformation = action.payload;
    },
  },
});

export const { actions, name } = appSlice;
export default appSlice.reducer;
