import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { UserAccessLevelType } from 'store/reducers/app';

export const OnboardingQuickLinksComponent: FC = () => {
  const userAccessLevels = useAppSelector((state) => state.app.userAccessLevels);
  const invitePermissions: UserAccessLevelType[] = ['ACCOUNT_USERS_AND_PERMISSIONS', 'ADMIN'];

  return (
    <StyledContainer>
      <Typography fontWeight="bold">Setup quick links</Typography>
      <Button
        component={Link}
        to="/connect/information"
        variant="contained"
        color="primary"
        sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
      >
        Connect new Amazon account →
      </Button>
      <Button
        href="https://help.reasonautomation.com/new-seller-account"
        target="_blank"
        variant="contained"
        color="primary"
        sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
      >
        Invite to Seller Central →
      </Button>
      <Button
        href="https://help.reasonautomation.com/new-vendor-account"
        target="_blank"
        variant="contained"
        color="primary"
        sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
      >
        Invite to Vendor Central →
      </Button>
      {userAccessLevels.some((l) => invitePermissions.includes(l)) && (
        <Button
          component={Link}
          to="/account/users"
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none', justifyContent: 'flex-start' }}
        >
          Add your team →
        </Button>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 25px;
`;
