import { OpenInNew } from '@mui/icons-material';
import { PublicSchemaReportSchemaType, usePpGetHelpUrlForTableMutation } from 'graphql/types';
import styled from 'styled-components';
import { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { gql } from '@apollo/client';

export type ReportHelpRedirectLinkProps = {
  schemaType: PublicSchemaReportSchemaType;
  tableName: string;
  reportHelpUrl: string | null;
  allowFuzzySearch?: boolean;
};

export const ReportHelpRedirectLink: FC<ReportHelpRedirectLinkProps> = ({
  schemaType,
  tableName,
  reportHelpUrl,
  allowFuzzySearch,
}) => {
  const [getHelpUrlForTableMutation] = usePpGetHelpUrlForTableMutation();

  const showDescriptionButton = !!reportHelpUrl?.trim() || allowFuzzySearch;

  if (!showDescriptionButton) {
    return null;
  }

  return (
    <DescriptionButton
      onClick={async (el) => {
        let redirectUrl = reportHelpUrl?.trim() || '';

        if (!redirectUrl) {
          if (!allowFuzzySearch) throw new Error('unexpected !allowFuzzySearch');

          // change color, make the link non-clickable, get url (ASYNC), revert color/clickable state and open new tab
          const elementTarget = el.currentTarget;
          const c = elementTarget.style.color;
          elementTarget.style.color = '#aaaaaa';
          elementTarget.disabled = true;

          redirectUrl = `https://help.reasonautomation.com`;
          try {
            const { data } = await getHelpUrlForTableMutation({ variables: { schemaType, tableName } });
            redirectUrl = data?.getHelpUrlPublicSchema?.url ?? redirectUrl;
          } catch {
            // swallow
          }
          elementTarget.style.color = c;
          elementTarget.disabled = false;
        }

        window.open(redirectUrl, '_blank')?.focus();
      }}
    >
      <Typography style={{ paddingRight: 4 }} title="Open in a new tab">
        View documentation
      </Typography>
      <OpenInNew />
    </DescriptionButton>
  );
};

const DescriptionButton = styled(Button)({
  textTransform: 'none',
  fontSize: 14,
});

export const pp_getHelpUrlForTable = gql`
  mutation pp_getHelpUrlForTable($schemaType: PublicSchemaReportSchemaType!, $tableName: String!) {
    getHelpUrlPublicSchema(schemaType: $schemaType, tableName: $tableName) {
      url
    }
  }
`;
