import { gql } from '@apollo/client';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  useGetNewPartnerOnboardingChecklistLazyQuery,
  useUpdatePublicPortalChecklistItemMutation,
} from 'graphql/types';
import { FC, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';

export const OnboardingChecklistComponent: FC = () => {
  const currentPartnerId = useAppSelector((state) => state.app.partnerId);
  const [loadChecklist, { data, error }] = useGetNewPartnerOnboardingChecklistLazyQuery();
  const [updateChecklistItem, { error: updateError }] = useUpdatePublicPortalChecklistItemMutation();

  const handleChange = async (item: { id: string; completed: boolean }) => {
    if (!data?.newPartnerOnboardingChecklist?.items) {
      return;
    }

    await updateChecklistItem({
      variables: {
        partnerId: currentPartnerId,
        checklistItemId: item.id,
        completed: !item.completed,
      },
      // optimistically update the UI immediately.
      // If the mutation fails, the UI will be updated to reflect the server state
      optimisticResponse: {
        updatePublicPortalChecklistItem: {
          ...data.newPartnerOnboardingChecklist,
          items: data?.newPartnerOnboardingChecklist?.items.map((i) =>
            i.id === item.id ? { ...i, completed: !i.completed } : i
          ),
        },
      },
      update: (cache, { data }) => {
        if (!data) {
          return;
        }
        cache.writeQuery({
          query: GetNewPartnerOnboardingChecklistQuery,
          variables: { partnerId: currentPartnerId },
          data: { newPartnerOnboardingChecklist: data.updatePublicPortalChecklistItem },
        });
      },
    });
  };

  useEffect(() => {
    loadChecklist({ variables: { partnerId: currentPartnerId } });
  }, [currentPartnerId, loadChecklist]);

  return (
    <>
      {data?.newPartnerOnboardingChecklist?.items.length && (
        <div>
          <Typography fontWeight="bold" textAlign={'left'}>
            New customer setup checklist
          </Typography>
          {data?.newPartnerOnboardingChecklist?.items.map((item) => (
            <div>
              <FormControlLabel
                control={<Checkbox checked={item.completed} onChange={() => handleChange(item)} />}
                label={item.value}
              />
            </div>
          ))}
        </div>
      )}
      {(error || updateError) && (
        <div>
          {error && <StyledErrorContainer>{error?.message}</StyledErrorContainer>}
          {updateError && <StyledErrorContainer>{updateError?.message}</StyledErrorContainer>}
        </div>
      )}
    </>
  );
};

export const GetNewPartnerOnboardingChecklistQuery = gql`
  query GetNewPartnerOnboardingChecklist($partnerId: String) {
    newPartnerOnboardingChecklist(partnerId: $partnerId) {
      id
      name
      description
      items {
        id
        value
        order
        completed
      }
    }
  }
`;

export const UpdateChecklistItemMutation = gql`
  mutation UpdatePublicPortalChecklistItem($partnerId: String, $checklistItemId: String!, $completed: Boolean!) {
    updatePublicPortalChecklistItem(partnerId: $partnerId, checklistItemId: $checklistItemId, completed: $completed) {
      id
      name
      description
      items {
        id
        value
        order
        completed
      }
    }
  }
`;

const StyledErrorContainer = styled.div`
  color: red;
  margin-top: 8px;
`;
