import styled from 'styled-components';
import { FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export type CircularSpinnerProps = {
  shown: boolean;
  message?: string;
};

export const CircularSpinner: FC<CircularSpinnerProps> = ({ shown, message }) => {
  const boxWidth = 256;
  const spinnerWidth = 90;

  const backdrop = '#DADADA'; // #282828 for dark themes

  return (
    <Dialog open={shown} disableScrollLock={true}>
      <DialogContent sx={{ width: boxWidth, height: boxWidth, background: { backdrop } }}>
        <CircularProgress
          size={spinnerWidth}
          sx={{
            position: 'absolute',
            top: `calc(50% - ${spinnerWidth >> 1}px + 8px)`,
            left: `calc(50% - ${spinnerWidth >> 1}px)`,
            zIndex: 10001,
          }}
        />
        <StyledButtonContainer>
          <LoadingLabel>{message || 'Loading'}...</LoadingLabel>
        </StyledButtonContainer>
      </DialogContent>
    </Dialog>
  );
};

const StyledButtonContainer = styled.div`
  height: 100%;
  margin-bottom: 0;
`;

const LoadingLabel = styled(Typography)({
  textAlign: 'center',
});
