import { createBrowserRouter } from 'react-router-dom';

import { AppLayout } from 'AppLayout';
import { ConnectAccountView } from 'components/connectAccountView/ConnectAccountView';
import { ConnectionManagementView } from 'components/connectionManagement/ConnectionManagementView';
import { Logout } from 'components/logout/Logout';
import { NodeView } from 'components/nodeView/NodeView';
import { ManageYourData } from 'components/manageYourData/ManageYourData';
import { LWACallback } from 'components/lwaCallback/LWACallback';
import { HelpWrapView } from 'components/help/HelpWrapView';
import { AccountWrapView } from 'components/account/AccountWrapView';
import { ReportView } from 'components/reportView/ReportView';
import { DatabaseCredentials } from 'components/databaseCredentials/databaseCredentials';
import { Checkout } from 'components/checkout/Checkout';
import { DataTablesView } from 'components/dataTables/DataTablesView';
import { DashboardView } from 'components/dashboardView/DashboardView';
import { BackfillStatusView } from 'components/backfillStatus/BackfillStatusView';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <DashboardView />,
      },
      {
        path: '/connect/callback',
        element: <LWACallback />,
      },
      {
        path: '/connect/*',
        element: <ConnectAccountView />,
      },
      {
        path: '/data/*',
        element: <ManageYourData />,
      },
      {
        path: '/data/:property',
        element: <NodeView />,
      },
      {
        path: '/data/credentials',
        element: <DatabaseCredentials />,
      },
      {
        path: '/data/tables',
        element: <DataTablesView />,
      },
      {
        path: '/data/connection',
        element: <ConnectionManagementView />,
      },
      {
        path: '/data/backfillStatus',
        element: <BackfillStatusView />,
      },
      {
        path: '/account/:property',
        element: <AccountWrapView />,
      },
      {
        path: '/account/:property/:type',
        element: <AccountWrapView />,
      },
      {
        path: '/help/:property',
        element: <HelpWrapView />,
      },
      {
        path: '/reports/:groupId/:id',
        element: <ReportView />,
      },
      {
        path: '/payments/*',
        element: <Checkout />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
    ],
  },
]);
