import { Button, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { BackfillStatus, BackfillStatusDetail } from 'helpers/userPermissions';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { ReportHelpRedirectLink } from 'components/reportHelpRedirectLink/reportHelpRedirectLink';

type BackfillStatusDetailModalProps = {
  open: boolean;
  entry: BackfillStatus;
  onClose: () => void;
};

export const BackfillStatusDetailModal: FC<BackfillStatusDetailModalProps> = ({ open, entry, onClose }) => {
  const windowWidth = window.innerWidth;
  // const windowHeight = window.innerHeight;
  const [data, setData] = useState<BackfillStatusDetail[]>([]);

  useEffect(() => {
    const data = (entry.reports ?? []);
    setData(data);
  }, [entry]);

  const Columns = useMemo(() => {
    return [
      {
        label: 'Schema',
        name: 'outputSchemaType',
        options: {
          display: false,
        },
      },
      {
        label: 'Table',
        name: 'table',
      },
      {
        label: 'Name',
        name: 'friendlyName',
      },
      {
        label: 'Description',
        name: 'description',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];

            return <ReportHelpRedirectLink schemaType={record.schema} tableName={record.table} reportHelpUrl={record.reasonAutomationHelpUrl} />
          },
        },
      },
      {
        label: 'Periods',
        name: 'periods',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex: number) => {
            const record = data[dataIndex];

            return (
              <>
                {record?.periods?.map((p, pI) => (<PeriodLabel style={{ paddingRight: 4 }} key={`${record.table}-${pI}`} title={`FROM: ${p.fromDate}\nTO: ${p.toDate}`}>{p.period} [{p.fromDate?.split(',')?.[0] ?? ''} ... {p.toDate?.split(',')?.[0] ?? ''}]</PeriodLabel>))}
              </>
            );
          },
        },
      },
    ] as MUIDataTableColumnDef[];
  }, [data]);

  const options: MUIDataTableOptions = {
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    tableBodyMaxHeight: '442px',
    sortOrder: {
      name: 'table',
      direction: 'asc',
    },
  };

  const startedAtFriendlyDateTime = new Date(Date.parse(entry.started)).toLocaleString();

  // height computation -- TABLE: header 69px, footer 64px, row approx 69px (1 period) to 105px (3 periods) -- REST of FORM: 258px
  const modelHeight = Math.min(700, ((data?.length ?? 0) * 69) + 69 + 64 + 258);

  return (
    <Modal width={windowWidth - 256} height={modelHeight} open={open} onClickOutside={onClose} title={`Backfill details started at ${startedAtFriendlyDateTime}:`}>
      <StyledContainer>
        <MUIDataTable title="" columns={Columns} data={data} options={options} />
        <StyledButtonContainer>
          <StyledOKButton variant={'contained'} onClick={onClose}>
            OK
          </StyledOKButton>
        </StyledButtonContainer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 0;
`;

const StyledOKButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '40px',
  backgroundColor: '#23A4A4',
  marginLeft: '16px',
});

const PeriodLabel = styled(Typography)({
  // textDecoration: 'underline',
  // color: 'blue',
  // cursor: 'pointer',
});
