import { Button, IconButton, Typography } from '@mui/material';
import { Modal } from 'components/modal/modal';
import { Report } from 'powerbi-client';
import { LineProgressBar } from 'components/progressbar/LineProgressBar';
import {
  PowerBiReportExportFormat,
  PowerBiReportPageFragmentFragment,
  useGetPowerBiReportPagesQuery,
  usePbiExportMutation,
  usePbiExportRefreshStatusMutation,
} from 'graphql/types';
import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from '@gilbarbara/react-dropdown';
import Checkbox from '@mui/material/Checkbox';
import { green } from '@mui/material/colors';
import { trackGoogleAnalyticsClickEvent } from 'helpers/analytics';
import { useAppSelector } from 'store/hooks';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

export type PowerBIDownloadModalProps = {
  report?: Report;
  reportId?: string;
  groupId?: string;
  open: boolean;
  onRequestClose?: () => void;
};

export const PowerBIDownloadModal: FC<PowerBIDownloadModalProps> = ({
  report,
  reportId,
  groupId,
  open,
  onRequestClose,
}) => {
  const selectedNodeName = useAppSelector((state) => state.app.selectedNodeName);
  const [pages, setPages] = useState<PowerBiReportPageFragmentFragment[]>([]);
  const [selectedPages, setSelectedPages] = useState<PowerBiReportPageFragmentFragment[]>([]);
  const [selectedExportType, setSelectedExportType] = useState<PowerBiReportExportFormat>(
    PowerBiReportExportFormat.Pdf
  );
  const [progress, setProgress] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [exportId, setExportId] = useState<string | null>(null);
  const [isExporting, setIsExporting] = useState(false);
  const [isDownloading, setDownloading] = useState(false);

  const [getPbiExport] = usePbiExportMutation();
  const [getPbiExportRefreshStatus] = usePbiExportRefreshStatusMutation();

  const { data: pagesData, loading: loadingPages } = useGetPowerBiReportPagesQuery({
    variables: {
      reportId: reportId ?? '',
      groupId: groupId ?? '',
    },
  });

  useEffect(() => {
    // initial load
    if (!pagesData) return;
    setPages(pagesData.listPowerBIReportPages);
    // RAR-134 - do not select by default the pages ending by "_"
    setSelectedPages(pagesData.listPowerBIReportPages.filter((p) => !p.displayName.match(/_$/i)));
  }, [pagesData]);

  const formatOptions = [
    {
      label: 'PDF',
      value: PowerBiReportExportFormat.Pdf,
    },
    {
      label: 'PPTX',
      value: PowerBiReportExportFormat.Pptx,
    },
  ];

  const handleSelectPage = (page: PowerBiReportPageFragmentFragment) => {
    if (selectedPages.some((p) => p.displayName === page.displayName)) {
      const selected = selectedPages.filter((p) => p.displayName !== page.displayName);
      setSelectedPages(selected);
    } else {
      const selected = [...selectedPages, page];
      setSelectedPages(selected);
    }
  };

  const downloadFile = useCallback(
    async (downloadUrl: string) => {
      setDownloading(true);

      try {
        const response = await fetch(downloadUrl, {
          method: 'GET',
        });

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${reportId ?? ''}.${selectedExportType === PowerBiReportExportFormat.Pptx ? 'pptx' : 'pdf'}`;
        a.click();

        setIsExporting(false);
        setExportId(null);
        setProgress(0);
        setErrorMsg('');
        setDownloading(false);
        onRequestClose?.();

        return true;
      } catch (e) {
        setDownloading(false);
        setErrorMsg('Failed to download file.');

        return false;
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedExportType, reportId, setProgress, onRequestClose]
  );

  const fetchPbiExport = useCallback(async () => {
    if (!reportId || !groupId || !selectedExportType || exportId || !report) {
      return;
    }

    if (selectedExportType === PowerBiReportExportFormat.Pdf) {
      trackGoogleAnalyticsClickEvent('Download PDF clicked', 'PowerBI Report');
    } else {
      trackGoogleAnalyticsClickEvent('Download PPTX clicked', 'PowerBI Report');
    }

    setIsExporting(true);
    try {
      setProgress(0);
      const bookmark = await report.bookmarksManager.capture({
        allPages: true,
        personalizeVisuals: true,
      });

      const exportingPages = selectedPages.map((p) => {
        return { pageName: p.pageName };
      });

      const pbiRes = await getPbiExport({
        variables: {
          reportId: reportId,
          groupId: groupId,
          format: selectedExportType,
          bookmarkState: bookmark.state,
          pages: exportingPages,
        },
      });

      if (exportId) {
        setIsExporting(false);
        return;
      }

      if (pbiRes && pbiRes.data?.exportPowerBIReport?.exportId) {
        setExportId(pbiRes.data?.exportPowerBIReport?.exportId);
        setProgress(1);
      } else {
        setIsExporting(false);
        setErrorMsg('Failed to download file');
      }
    } catch {
      setIsExporting(false);
      setErrorMsg('Failed to download file');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, reportId, groupId, selectedPages, selectedExportType, exportId, setProgress]);

  const fetchPbiExportStatus = useCallback(
    async (exportId: string, retryAttempt = 0) => {
      if (!reportId || !groupId || !selectedExportType || !exportId) {
        return;
      }
      try {
        const refreshStatusRes = await getPbiExportRefreshStatus({
          variables: {
            reportId: reportId,
            groupId: groupId,
            format: selectedExportType,
            exportId: exportId,
          },
        });

        if (refreshStatusRes.data?.refreshPowerBIReportExportStatus) {
          const status = refreshStatusRes.data?.refreshPowerBIReportExportStatus;

          if (status.exportCompletionPercentage > 1) {
            setProgress(status.exportCompletionPercentage);
          }
          if (status.downloadUrl) {
            setProgress(100);
            const downloadPassed = await downloadFile(status.downloadUrl);

            if (!downloadPassed && retryAttempt < 3) {
              setErrorMsg('');
              setTimeout(() => {
                fetchPbiExportStatus(exportId, retryAttempt + 1);
              }, 250);
            }
          } else {
            setTimeout(() => {
              fetchPbiExportStatus(exportId);
            }, 2000);
          }
        } else {
          setIsExporting(false);
          setErrorMsg('Failed to download file');
        }
      } catch {
        setIsExporting(false);
        setErrorMsg('Failed to download file');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [reportId, groupId, selectedExportType, downloadFile, getPbiExportRefreshStatus, setProgress]
  );

  useEffect(() => {
    if (!!exportId && progress === 1) {
      fetchPbiExportStatus(exportId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportId, progress]);

  return (
    <Modal onRequestClose={onRequestClose} width={800} height={500} open={open} title={'Export Report'}>
      <StyledContainer style={{ overflowY: 'hidden' }}>
        <Typography sx={{ fontSize: 18 }}>
          Please indicate the format and the content of the report <b>{selectedNodeName}</b>:
        </Typography>
        <StyledPageContainer>
          <CircularSpinner shown={loadingPages} />
          <Typography>Format:</Typography>
          <Dropdown
            options={formatOptions}
            disabled={isExporting}
            values={[formatOptions.find((o) => o.value === selectedExportType)]
              .filter((o) => !!o)
              .map(
                (o) =>
                  o as {
                    label: string;
                    value: PowerBiReportExportFormat;
                  }
              )}
            onChange={(e) => {
              setSelectedExportType(e[0].value as PowerBiReportExportFormat);
            }}
          />
        </StyledPageContainer>
        <Typography>
          Pages: &nbsp;&nbsp;&nbsp;
          <StyledRowButton
            onClick={() => {
              if (!pagesData) return;
              setSelectedPages(pagesData.listPowerBIReportPages);
            }}
          >
            [select all]
          </StyledRowButton>
          &nbsp;
          <StyledRowButton
            onClick={() => {
              setSelectedPages([]);
            }}
          >
            [unselect all]
          </StyledRowButton>
        </Typography>
        <StyledScrollingContainer>
          {pages
            // .flatMap((p) => [p, p, p, p, p, p, p, p, p, p]) // to test many pages
            .map((p, pI) => {
              const checked = selectedPages.some((e) => e.displayName === p.displayName);
              return (
                <StyledPageContainer key={`page_${pI}`}>
                  <IconButton
                    aria-label="user-admin"
                    sx={{ color: green[500] }}
                    disabled={isExporting}
                    onClick={() => handleSelectPage(p)}
                  >
                    <Checkbox
                      sx={{ padding: 0 }}
                      checked={checked}
                      color={checked ? 'success' : 'default'}
                      disabled={checked === false}
                    />
                  </IconButton>
                  <Typography>{p.displayName}</Typography>
                </StyledPageContainer>
              );
            })}
        </StyledScrollingContainer>
        <StyledSpaceContainer />
        {isExporting && (
          <Typography color={errorMsg.length > 0 ? 'error' : 'primary'}>
            {errorMsg.length > 0 ? errorMsg : isDownloading ? 'Preparing the download...' : 'Please wait...'}
          </Typography>
        )}
        {isExporting && <LineProgressBar value={progress} />}
        <StyledButtonContainer>
          <Button sx={{ height: 44 }} disabled={isExporting} onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            sx={{ height: 44 }}
            variant={'contained'}
            onClick={fetchPbiExport}
            disabled={selectedPages.length === 0 || isExporting}
          >
            Export
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const StyledPageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledSpaceContainer = styled.div`
  display: flex;
  flex: 1;
`;

const StyledScrollingContainer = styled.div`
  height: 200px;
  overflow-y: auto;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: right;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 'auto';
`;

const StyledRowButton = styled(Button)({
  textTransform: 'none',
  textDecoration: 'underline',
  fontSize: '14px',
  fontWeight: '400',
  paddingLeft: 0,
});
