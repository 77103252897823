import { Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';

// define hubspot type on window
declare global {
  interface Window {
    hbspt: unknown;
  }
}

export const HubSpotSchedulerComponent: FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const isFirefox = useMemo(() => navigator.userAgent.toLowerCase().indexOf('firefox') > -1, []);

  if (typeof window.hbspt === 'undefined' && isFirefox) {
    // hubspot is blocked in firefox in "Strict Tracking Protection" mode
    return (
      <div>
        <Typography fontWeight="bold">Schedule your free onboarding meeting</Typography>
        <Typography fontStyle="italic">
          Please schedule directly at{' '}
          <a
            target="_blank"
            href="https://meetings.hubspot.com/hannah-werme/new-customer-onboarding-help"
            rel="noreferrer"
          >
            HubSpot
          </a>
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography fontWeight="bold">Schedule your free onboarding meeting</Typography>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/hannah-werme/new-customer-onboarding-help?embed=true"
      ></div>
    </div>
  );
};

// cSpell:words hbspt
