import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { HubSpotSchedulerComponent } from './parts/HobSpotSchedulerComponent';
import { InstructionsComponent } from './parts/InstructionsComponent';
import { useGetNewPartnerOnboardingChecklistQuery } from 'graphql/types';
import { useAppSelector } from 'store/hooks';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { ConnectionManagementView } from 'components/connectionManagement/ConnectionManagementView';

export const DashboardView: FC = () => {
  const { user } = useAuth0();
  const currentPartnerId = useAppSelector((state) => state.app.partnerId);
  const { data, loading } = useGetNewPartnerOnboardingChecklistQuery({
    variables: {
      partnerId: currentPartnerId,
    },
  });

  useEffect(() => {
    trackGoogleAnalyticsPageView('/', 'Dashboard');
  }, []);

  if (loading || !data) {
    return <></>;
  }

  return (
    <StyledContainer>
      <Typography textAlign={'center'}>Welcome! You are logged in as {user?.email}.</Typography>
      <CircularSpinner shown={loading} />
      {data.newPartnerOnboardingChecklist ? (
        <SplitContainer>
          <StyledContainer style={{ flexGrow: 1 }}>
            <InstructionsComponent />
          </StyledContainer>
          <StyledContainer style={{ flexGrow: 2 }}>
            <HubSpotSchedulerComponent />
          </StyledContainer>
        </SplitContainer>
      ) : (
        <ConnectionManagementView />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5vh;
`;

const SplitContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 16px;
`;
