import { FC } from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import styled from 'styled-components';
import { Paper } from '@mui/material';

export type HubspotContactFormPros = {
  portalId: string;
  formId: string;
};

export const HubspotContactForm: FC<HubspotContactFormPros> = ({ portalId, formId }) => {

  const { formCreated } = useHubspotForm({
    portalId: portalId,
    formId: formId,
    target: '#hubspotForm'
  });

  return (
    <StyledPaper elevation={formCreated ? 4 : 0}>
      <div id="hubspotForm"></div>
    </StyledPaper>
  );
}


const StyledPaper = styled(Paper)`
  padding: 32px;
  margin-top: 46px;
  width: 45%;
  min-width: 480px;
  @media only screen and (max-width: 768px) {
    width: calc(100% - 32px);
  }
`;
