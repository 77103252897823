import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import {
  PpPublicPartnerUserFragment,
  StripeCurrencyType,
  useUserManagementCancelBrandDashboardSeatPublicUserMutation,
} from 'graphql/types';
import { Dropdown } from '@gilbarbara/react-dropdown';
import moment from 'moment';
import { useMuiNotifications } from 'context/muiNotificationContext';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

export enum SeatActionType {
  Assign = 'assign',
  Unassign = 'unassign',
  Cancel = 'Cancel',
  Cancelled = 'Cancelled',
}

type SeatActionConfirmationModalProps = {
  open: boolean;
  type: SeatActionType;
  brandId: string;
  seatId: string;
  dashboardName: string;
  brandName: string;
  email: string;
  partners: PpPublicPartnerUserFragment[];
  onOK: (userId?: string, cancel?: boolean) => void;
  onClose: () => void;
};

export const SeatActionConfirmationModal: FC<SeatActionConfirmationModalProps> = ({
  open,
  type,
  brandId,
  seatId,
  brandName,
  dashboardName,
  email,
  partners,
  onOK,
  onClose,
}) => {
  const { createFailureNotification } = useMuiNotifications();
  const [cancelSeatEvalMutation, { loading: loadingCancelInfo }] =
    useUserManagementCancelBrandDashboardSeatPublicUserMutation();
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [renewAmount, setRenewAmount] = useState<number | null | undefined>(null);
  const [renewCurrency, setRenewCurrency] = useState<StripeCurrencyType | null | undefined>(null);
  const [cancelOn, setCancelOn] = useState<string | null | undefined>(null);
  const [gotError, setGotError] = useState<boolean>(false);
  const [hasOpened, setHasOpened] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    bgcolor: '#fff',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
  };

  const options = partners.map((e) => {
    return {
      label: e.email,
      value: e.id,
    };
  });

  useEffect(() => {
    if (open && !hasOpened) {
      setHasOpened(true);
      setRenewAmount(null);
      setRenewCurrency(null);
      setCancelOn(null);
      setGotError(false);
      if (type === SeatActionType.Cancel || type === SeatActionType.Cancelled) {
        setForceRefresh(true);
      }
    }
  }, [open, type, hasOpened]);

  const handleClose = () => {
    setHasOpened(false);
    setForceRefresh(false);
    onClose();
  };

  useEffect(() => {
    const getCancelledInfo = async () => {
      if (seatId.length === 0) return;
      if (gotError) return;
      try {
        const result = await cancelSeatEvalMutation({
          variables: {
            brandId: brandId,
            dashboardName: dashboardName,
            id: seatId,
            evalOnly: true,
          },
        });
        const data = result.data?.cancelBrandDashboardSeatPublicUser;
        if (!!data) {
          setRenewAmount(data.renewAmount);
          setRenewCurrency(data.renewAmountCurrency);
          setCancelOn(data.cancelOn);
        }
      } catch (e) {
        createFailureNotification('Failed to get seat details. Please contact support');
        setGotError(true);
      }
    };
    getCancelledInfo();
  }, [gotError, forceRefresh, seatId, brandId, dashboardName, cancelSeatEvalMutation, createFailureNotification]);

  const handleOk = () => {
    if (type === SeatActionType.Assign) {
      onOK(selectedUserId);
    } else if (type === SeatActionType.Cancel) {
      onOK(undefined, true);
    } else {
      onOK();
    }
  };

  const title = useMemo(() => {
    switch (type) {
      case SeatActionType.Assign:
        return `Assigning seat for ${dashboardName} [${brandName}]`;
      case SeatActionType.Unassign:
        return `Un-assigning seat for ${dashboardName} [${brandName}]`;
      case SeatActionType.Cancel:
        return `Cancelling seat for ${dashboardName} [${brandName}]`;
      case SeatActionType.Cancelled:
        return `Details for cancelled seat for ${dashboardName} [${brandName}]`;
      default:
        return '';
    }
  }, [type, dashboardName, brandName]);

  const message = useMemo(() => {
    let currency = '$';
    if (!!renewCurrency) {
      switch (renewCurrency) {
        case StripeCurrencyType.Usd:
          currency = '$';
          break;
      }
    }

    const amount = (renewAmount ?? 0) / 100;
    const cancelDate = moment(cancelOn).format('MMMM D, YYYY');

    switch (type) {
      case SeatActionType.Assign:
        return 'Please assign seat to user:';
      case SeatActionType.Unassign:
        return `Please confirm removal of the assignment of seat for user ${email}.`;
      case SeatActionType.Cancel:
        const cancelString = `Please confirm cancellation of the seat subscription of ${currency}${amount}. The cancellation will be effective on ${cancelDate}.`;
        return loadingCancelInfo ? 'Please wait...' : cancelString;
      case SeatActionType.Cancelled:
        const cancelledString = `The seat subscription of ${currency}${amount} will be cancelled on ${cancelDate}.`;
        return loadingCancelInfo ? 'Please wait...' : cancelledString;
      default:
        return '';
    }
  }, [type, email, loadingCancelInfo, renewAmount, cancelOn, renewCurrency]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ color: '#000', marginBottom: 4, textAlign: 'center' }}
        >
          {title}
        </Typography>
        <MessageLabel id="modal-modal-description" sx={{ mt: 2 }}>
          {message}
        </MessageLabel>
        <CircularSpinner shown={loadingCancelInfo} />
        {type === SeatActionType.Assign && (
          <Dropdown
            options={options}
            values={[selectedUserId !== '' ? options.find((o) => o.value === selectedUserId) ?? undefined : undefined]
              .filter((o) => !!o)
              .map(
                (o) =>
                  o as {
                    label: string;
                    value: string;
                  }
              )}
            onChange={(c) => {
              if (c?.length) {
                setSelectedUserId(c[0].value as string);
              }
            }}
          />
        )}
        <StyledButtonContainer>
          {type !== SeatActionType.Cancelled && <StyledCancelButton onClick={handleClose}>CANCEL</StyledCancelButton>}
          <StyledSaveButton
            variant={'contained'}
            onClick={handleOk}
            disabled={
              loadingCancelInfo ||
              (type === SeatActionType.Cancel && !renewAmount) ||
              (type === SeatActionType.Cancelled && !renewAmount) ||
              gotError ||
              (type === SeatActionType.Assign && !selectedUserId)
            }
          >
            OK
          </StyledSaveButton>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

const MessageLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '4px',
  color: '#000',
});

const StyledButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: right;
  gap: 24px;
  margin-top: 48px;
`;

const StyledSaveButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '36px',
  backgroundColor: '#23A4A4',
  fontSize: 16,
});

const StyledCancelButton = styled(Button)({
  textTransform: 'none',
  width: '160px',
  height: '36px',
  color: '#000',
  fontSize: 16,
});
