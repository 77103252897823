import { gql } from '@apollo/client';
import { Typography } from '@mui/material';
import { PublicPartnerCredentialsPostgreSql, PublicPartnerCredentialsSnowflake, PublicPartnerCredentialsType, useGetPublicPartnerCredentialsQuery } from 'graphql/types';
import { FC, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { PostgresDatabase } from './parts/postgresDatabase';
import { SnowflakeDatabase } from './parts/snowflakeDatabase';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

export const DatabaseCredentials: FC = () => {
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const { data: credentialsResponse, loading } = useGetPublicPartnerCredentialsQuery({
    variables: {
      publicPartnerId: partnerId ?? '',
    },
    skip: partnerId == null
  });

  const { postgresData, snowflakeData } = useMemo(() => {
    const data = credentialsResponse?.publicPartnerCredentials || [];

    const pData = data.filter((item) => (item.type === PublicPartnerCredentialsType.Postgresql && item.postgresql)).map((item) => item.postgresql);
    const sData = data.filter((item) => (item.type === PublicPartnerCredentialsType.Snowflake && item.snowflake)).map((item) => item.snowflake);

    const postgresData: PublicPartnerCredentialsPostgreSql[] = [];
    const snowflakeData: PublicPartnerCredentialsSnowflake[] = [];

    pData.forEach(item => {
      if (!!item) {
        postgresData.push(item);
      }
    });
    sData.forEach(item => {
      if (item) {
        snowflakeData.push(item);
      }
    });

    return {
      postgresData,
      snowflakeData,
    }

  }, [credentialsResponse]);

  return (
    <StyledContainer>
      <Typography sx={{
        fontSize: 30,
        fontWeight: 800,
      }} textAlign={'center'}>Database Credentials</Typography>

      <CircularSpinner shown={loading} />
      {postgresData.length > 0 && (
        <PostgresDatabase data={postgresData} />
      )}
      {snowflakeData.length > 0 && (
        <SnowflakeDatabase data={snowflakeData} />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;

export const GetPublicPartnerCredentials = gql`
  query GetPublicPartnerCredentials($publicPartnerId: String!) {
    publicPartnerCredentials(partnerId: $publicPartnerId) {
      type
      postgresql {
        host
        port
        ssl
        database
        userName
        password
      }
      snowflake {
        message
      }
    }
  }
`;