import { BackfillStatusRequestedByType, BackfillStatusType, PpDataTablesNoBackfillsAvailableQuery, PpDataTablesWithBackfillsAvailableQuery, PublicSchemaReportSchemaType, PublicUserBrandFragmentFragment, StripeCurrencyType } from 'graphql/types';
import { capitalizeFirstLetterOfEachWord } from './strings';

export interface BrandPermissionStateProps {
  userId: string;
  brands: UserBrand[];
}

export interface UserBrandDashboardSeatPublicUser {
  id: string;
  email: string;
}

export interface UserBrandDashboardSeat {
  id: string;
  isSoftCancelled: boolean;
  isFreeSeat: boolean;
  assignedToEmail?: string | null | undefined;
  assignedToPublicUser?: UserBrandDashboardSeatPublicUser[];
}

export interface UserBrandDashboard {
  name: string;
  isEnabled: boolean;
  seatPriceInCents: number;
  seatPriceCurrency: StripeCurrencyType;
  seats: UserBrandDashboardSeat[];
}

export interface UserBrand {
  publicPartnerId: string;
  name: string;
  reasonPartnerUuid?: string | null | undefined;
  type: string;
  country: string;
  canReadData: boolean;
  canManageConnections: boolean;
  canBuyData: boolean;
  isAdmin: boolean;
  isBillingAdmin: boolean;
  dashboards: UserBrandDashboard[];
}

export class UserBrandPermission implements UserBrand {
  id: string;
  publicPartnerId: string;
  name: string;
  reasonPartnerUuid?: string | null | undefined;
  type: string;
  country: string;
  canReadData: boolean;
  canManageConnections: boolean;
  canBuyData: boolean;
  isAdmin: boolean;
  isBillingAdmin: boolean;
  dashboards: UserBrandDashboard[];

  constructor(fragment: PublicUserBrandFragmentFragment, isForSelect: boolean) {
    this.id = fragment.id;
    this.publicPartnerId = fragment.publicPartnerId;
    this.name = fragment.name;
    this.reasonPartnerUuid = fragment.reasonPartnerUuid;
    this.type = fragment.type;
    this.country = fragment.country;
    if (isForSelect) {
      this.canReadData = false;
      this.canManageConnections = false;
      this.canBuyData = false;
      this.isAdmin = false;
      this.isBillingAdmin = false;
      this.dashboards = [];
    } else {
      this.canReadData = fragment.canReadData;
      this.canManageConnections = fragment.canManageConnections;
      this.canBuyData = fragment.canBuy;
      this.isAdmin = fragment.isAdmin;
      this.isBillingAdmin = fragment.isBillingAdmin;
      this.dashboards = fragment.dashboards.map((fd) => {
        const seats = fd.seats.map((seat) => {
          let publicUsers: UserBrandDashboardSeatPublicUser[] = [];
          seat.assignedToPublicUser.forEach((e) => {
            if (!!e) {
              const user = {
                id: e.id,
                email: e.email,
              };
              publicUsers = [...publicUsers, user];
            }
          });

          return {
            id: seat.id,
            isSoftCancelled: seat.isSoftCancelled,
            isFreeSeat: seat.isFreeSeat,
            assignedToEmail: seat.assignedToEmail,
            assignedToPublicUser: publicUsers,
            // TODO: seat pricing is at seat level, not dashboard level
          };
        });
        return {
          name: capitalizeFirstLetterOfEachWord(fd.name),
          isEnabled: fd.isEnabled,

          // TODO: move this to the seat level
          seatPriceInCents: 0,
          seatPriceCurrency: 'USD' as StripeCurrencyType,
          // seatPriceInCents: fd.seatPriceInCents,
          // seatPriceCurrency: fd.seatPriceCurrency,

          seats: seats,
        };
      });
    }
  }
}

export enum DataTableSourceCheckType {
  checked,
  unchecked,
  warning,
  disabled,
}

export interface DataTableSource {
  checkType: DataTableSourceCheckType;
  table: string;
  schemaType: PublicSchemaReportSchemaType;
  friendlyName: string;
  updateFrequency: string;
  periods: string;
  record: PpDataTablesNoBackfillsAvailableQuery['listDataTables'][0];
  enableBackfill: boolean;
  reasonAutomationHelpUrl: string;
}

export interface BackfillOrderSource {
  table: string;
  friendlyName: string;
  periods: string;
  availableHistory: string;
  backfill: DataTableSourceCheckType;
  price: string;
  priceInCents: number;
  priceCurrency: string;
  backfillOrdered: PpDataTablesWithBackfillsAvailableQuery['listDataTables'][0]['backfillsAvailable'][0] | null;
  record: PpDataTablesWithBackfillsAvailableQuery['listDataTables'][0];
}

export interface BackfillStatus {
  id: string;
  status: BackfillStatusType;
  started: string;
  lastUpdated: string;
  requester: string;
  requestedBy: BackfillStatusRequestedByType;
  reports: BackfillStatusDetail[];
  progress: number | null;
}

export interface BackfillStatusDetail {
  table: string;
  schema: PublicSchemaReportSchemaType;
  friendlyName: string;
  description: string;
  periods: BackfillStatusPeriodDetail[];
  reasonAutomationHelpUrl: string;
}

export interface BackfillStatusPeriodDetail {
  period: string;
  fromDate: string;
  toDate: string;
}
