import { Refresh } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';

export type TableRefreshButtonProps = {
  onClick: () => void;
};

export const TableRefreshButton: FC<TableRefreshButtonProps> = ({ onClick }) => {
  return (
    <Tooltip title={'Refresh'} placement="bottom">
      <IconButton onClick={() => onClick()}>
        <Refresh />
      </IconButton>
    </Tooltip>
  );
};
