import { Link, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';
import { HubspotContactForm } from './parts/HubspotContactForm';

export const HelpReportProblemView: FC = () => {
  return (
    <StyledContainer>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}
      >
        Get Help
      </Typography>
      <StyledNormalTypography sx={{ mt: '24px' }} textAlign={'center'}>
        Please use the form below to submit a support request. Our team will respond within 1 business day.
      </StyledNormalTypography>
      <StyledNormalTypography textAlign={'center'}>
        Our business hours are 09:00 - 18:00 Pacific Time, Monday - Friday.
        <br />
        <br />
        In the interim, feel free to visit
        <StyledLink href="https://help.reasonautomation.com/" target="blank" underline="always" rel="noopener">
          Reason Automation Help Center
        </StyledLink>
        .
      </StyledNormalTypography>
      <HubspotContactForm
        portalId={process.env.REACT_APP_HBSPT_PORTAL_ID ?? ''}
        formId={process.env.REACT_APP_HBSPT_FORM_ID ?? ''}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const StyledNormalTypography = styled(Typography)({
  fontSize: 12,
  fontWeight: 400,
  color: '#000000',
});

const StyledLink = styled(Link)`
  font-size: 13px;
  font-weight: 500;
  color: ${(p) => p.theme.palette.text.secondary};
  align-self: left;
  margin-left: 0.25em;
`;
