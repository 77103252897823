import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { trackGoogleAnalyticsPageView } from 'helpers/analytics';
import { BillingView } from './billing/BillingView';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useStripeSetupIntentMutation } from 'graphql/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { actions } from 'store/reducers/app';
import { UserManagementWrapView } from './userManagement/UserManagementWrapView';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK ?? '');

export const AccountWrapView: FC = () => {
  const { property } = useParams<{ property: string }>();
  const dispatch = useAppDispatch();
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const reloadSecret = useAppSelector((state) => state.app.reloadStripeClientSecret);
  const isSaving = useAppSelector((state) => state.app.savingBillingInformation);

  const [stripeSetupIntentMutation, { data, loading, error }] = useStripeSetupIntentMutation();
  const [options, setOptions] = useState<StripeElementsOptions>();

  const clientSecret = useMemo(() => {
    return data?.setupIntentStripe?.clientSecret
  }, [data]);

  useEffect(() => {
    if (!clientSecret) {
      return;
    }

    setOptions({
      clientSecret: clientSecret,
    });
    dispatch(actions.setStripeClientSecret(clientSecret));
  }, [clientSecret, dispatch]);

  useEffect(() => {
    if (!property) {
      return;
    }

    if (partnerId && property === 'billing') {
      stripeSetupIntentMutation({
        variables: {
          partnerId: partnerId
        }
      })
    }
  }, [property, partnerId, stripeSetupIntentMutation]);

  useEffect(() => {
    if (!property) {
      return;
    }

    if (!reloadSecret) {
      return;
    }

    if (partnerId && property === 'billing') {
      stripeSetupIntentMutation({
        variables: {
          partnerId: partnerId
        }
      })
    }
  }, [property, reloadSecret, partnerId, stripeSetupIntentMutation]);

  useEffect(() => {
    dispatch(actions.setReloadStripeClientSecret(false));
  }, [data, error, dispatch]);

  useEffect(() => {
    if (property) {
      trackGoogleAnalyticsPageView(`/account/${property}`, 'Billing Admin');
    }
  }, [property]);

  const getChildren = () => {
    if (!!property && property === 'billing' && !!options) {
      return <Elements stripe={stripePromise} options={options}><BillingView /></Elements>;
    } else if (!!property && property === 'users') {
      return <UserManagementWrapView />
    }

    return null;
  };

  return (
    <StyledContainer>
      <CircularSpinner shown={loading || isSaving} />
      {error && <StyledErrorContainer>{error.message}</StyledErrorContainer>}
      {getChildren()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 32px;
`;

const StyledErrorContainer = styled.div`
  color: red;
  margin-top: 8px;
`;