import { gql } from '@apollo/client';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { Table, TableProps } from 'components/table/Table';
import { DataReportUploadFileDefinition, usePpAwaitForUploadedFileIngestionMutation } from 'graphql/types';
import { Box, Button, LinearProgress } from '@mui/material';
import { DownloadIconButton } from './DownloadIconButton';
import { FileDownloadDialog } from './FileDownloadDialog';
import { FileUploadButton } from './FileUploadButton';
import { TimeAgoTypograph } from './TimeAgoTypography';
import { DataViewer } from './DataViewer';
import { CircularSpinner } from 'components/circularSpinner/circularSpinner';
import { useAppSelector } from 'store/hooks';

export type NodeTableViewProps = {
  data: DataReportUploadFileDefinition[];
};

type RowDataType = DataReportUploadFileDefinition & {
  ingestionStatus: '' | 'VALIDATING' | 'INGESTING' | 'PUBLISHED';
  toggleUploadToIngesting: boolean;
};

// // ref: https://stackoverflow.com/a/65150997
// function useOnceCall(cb: () => void, condition = true) {
//   const isCalledRef = useRef(false);

//   useEffect(() => {
//     if (condition && !isCalledRef.current) {
//       isCalledRef.current = true;
//       cb();
//     }
//   }, [cb, condition]);
// }

export const NodeTableView: FC<NodeTableViewProps> = ({ data }) => {
  const partnerId = useAppSelector((state) => state.app.partnerId);
  const [downloadFileData, setDownloadFileData] = useState<DataReportUploadFileDefinition | null>(null);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<RowDataType[]>([]);
  const [awaitForUploadedFileIngestion] = usePpAwaitForUploadedFileIngestionMutation();
  const [doForceStatusRefresh, setDoForceStatusRefresh] = useState(false);
  const [validatingFiles, setValidatingFiles] = useState<RowDataType[]>([]);

  useEffect(() => {
    const newUploadedFiles = data.map(
      (c) =>
        ({
          ...c,
          ingestionStatus: c.hasDataUploaded ? 'VALIDATING' : '',
          toggleUploadToIngesting: false,
        } as RowDataType)
    );
    setUploadedFiles(newUploadedFiles);

    // async update for ingestion validation
    if (newUploadedFiles.length) {
      setValidatingFiles(newUploadedFiles.filter((f) => f.hasDataUploaded));
      setDoForceStatusRefresh(true);
    }

    return () => {
      console.debug('cleanup');
      setUploadedFiles([]);
      setDoForceStatusRefresh(false);
      setValidatingFiles([]);
    };
  }, [data]);

  useEffect(() => {
    console.log('doForceStatusRefresh', doForceStatusRefresh, 'validatingFiles.length', validatingFiles.length);
    if (doForceStatusRefresh && validatingFiles.length) {
      setDoForceStatusRefresh(false);
      const files = [...validatingFiles];
      validatingFiles.length = 0;
      files.forEach((f) => {
        console.debug('validating:', f.reportType, f);
        awaitForUploadedFileIngestion({
          variables: {
            partnerId: partnerId ?? '',
            reportType: f.reportType,
            silentOnSuccess: !f.toggleUploadToIngesting,
          },
        })
          .then((ingestResult) => {
            return ingestResult?.data?.awaitForUploadedFileIngestion?.isSuccess ?? false;
          })
          .catch(() => {
            return false;
          })
          .then((didIngest) => {
            const newUploadedFiles = [...uploadedFiles];
            const index = newUploadedFiles.findIndex((item) => item.reportType === f.reportType);
            newUploadedFiles[index].ingestionStatus = didIngest ? 'PUBLISHED' : 'INGESTING';
            newUploadedFiles[index].toggleUploadToIngesting = false;
            console.debug('validated:', f.reportType, newUploadedFiles[index].ingestionStatus);
            setUploadedFiles(newUploadedFiles);
          });
      });
    }
  }, [doForceStatusRefresh, awaitForUploadedFileIngestion, partnerId, uploadedFiles, validatingFiles]);

  const Columns: Column<RowDataType>[] = [
    {
      Header: 'Data type',
      accessor: 'dataTypeName',
    },
    {
      Header: 'Table name',
      accessor: 'tableName',
    },
    {
      Header: 'Download',
      accessor: 'downloadInstructions',
      Cell: ({ row: { original } }) => {
        return (
          <Box
            display={'flex'}
            sx={{
              width: '100%',
              height: '100%',
              paddingLeft: 1.5,
            }}
          >
            <DownloadIconButton
              partner={original}
              onDownload={(data) => {
                setDownloadFileData(data);
                setShowDownloadDialog(true);
              }}
            />
          </Box>
        );
      },
    },
    {
      Header: '',
      accessor: 'hasDataUploaded',
      Cell: ({ row: { original } }) => {
        if (original.hasDataUploaded === true) {
          return <DataViewer partner={original} />;
        } else {
          return <></>;
        }
      },
    },
    {
      Header: 'Last updated',
      accessor: 'lastUpdatedOn',
      Cell: ({ row: { original } }) => {
        return <TimeAgoTypograph isoTimeString={original.lastUpdatedOn ?? ''} />;
      },
    },
    {
      Header: 'Status',
      accessor: 'ingestionStatus',
      Cell: ({ row: { original } }) => {
        return <Typography>{original.ingestionStatus}</Typography>;
      },
    },
    {
      Header: 'Upload',
      accessor: 'reportType',
      Cell: ({ value, row: { original } }) => {
        if (original.toggleUploadToIngesting) {
          return <LinearProgress />;
        } else {
          return (
            <FileUploadButton
              reportType={value}
              partner={original}
              setUploading={(loading) => setShowLoader(loading)}
              fileUploaded={(data) => {
                if (data) {
                  const temp = [...uploadedFiles];
                  const currentIndex = temp.findIndex((item) => item.reportType === data);
                  temp[currentIndex] = {
                    ...temp[currentIndex],
                    hasDataUploaded: true,
                    lastUpdatedOn: new Date().toISOString(),
                    ingestionStatus: 'INGESTING',
                    toggleUploadToIngesting: true,
                  };
                  setUploadedFiles(temp);

                  setTimeout(() => {
                    console.debug('tracking status of upload', data);
                    const match = uploadedFiles.find((item) => item.reportType === data);
                    if (match) {
                      console.debug('starting tracking status of upload', match);
                      setValidatingFiles([{ ...match, toggleUploadToIngesting: true }]);
                      setDoForceStatusRefresh(true);
                    }
                  }, 5000); // 5 sec delay
                }
              }}
            />
          );
        }
      },
    },
  ];

  return (
    <StyledContainer>
      <Typography
        textAlign={'center'}
        sx={{
          fontSize: 30,
          fontWeight: 800,
          color: '#000000',
        }}
      >
        Upload Files to Your Database
      </Typography>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 500,
          color: '#000000',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        Download Template to get started. This page allows you to upload CSV files that can be made available in your
        Postgresql database. Download a template, fill it out, and upload it from this interface!
      </Typography>
      <StyledButton
        sx={{ width: 'fit-content' }}
        href={`mailto:contact@support.com?subject=I need help with this page`}
      >
        I need help with this page
      </StyledButton>
      <StyledTable columns={Columns} data={uploadedFiles} />
      <CircularSpinner shown={showLoader} />
      <FileDownloadDialog
        partner={downloadFileData}
        open={showDownloadDialog}
        onClose={() => {
          setShowDownloadDialog(false);
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const StyledTable = styled(Table)`
  flex: 1;
` as FC<TableProps<RowDataType>>;

const StyledButton = styled(Button)({
  gap: '4px',
  textTransform: 'none',
  fontSize: 12,
  fontWeight: 800,
  color: '#23A4A4',
  textAlign: 'center',
});

export const AwaitForUploadedFileIngestion = gql`
  mutation pp_awaitForUploadedFileIngestion(
    $partnerId: String!
    $reportType: DataReportType!
    $silentOnSuccess: Boolean!
  ) {
    awaitForUploadedFileIngestion(partnerId: $partnerId, reportType: $reportType, silentOnSuccess: $silentOnSuccess) {
      dataTypeName
      tableName
      isSuccess
    }
  }
`;
