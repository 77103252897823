import { Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon'

export type TimeAgoTypographyProps = {
  isoTimeString: string;
  title?: string;
};

export const TimeAgoTypograph: FC<TimeAgoTypographyProps> = ({ isoTimeString, title }) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isoStringToRelativeTime = (isoString: string, currentTime: number) => {
    if (currentTime === 0 && isoString === '') {
      return '';
    }
    return DateTime.fromISO(isoString).toRelative({ style: 'long' })
  }

  const children = <Typography>{isoStringToRelativeTime(isoTimeString, time)}</Typography>;

  return title ? <Tooltip title={title}>{children}</Tooltip> : children;
}